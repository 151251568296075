
const REFRESH_INTERVAL = 100000000

export function setToken(userToken) {
  sessionStorage.setItem('token', JSON.stringify(userToken));
}

export function getToken() {
  const tokenString = sessionStorage.getItem('token');
  try {
    const userToken = JSON.parse(tokenString);
    return userToken.token
  } catch (e) {
    return undefined;
  }
}

export function clearToken() {
  sessionStorage.setItem('token', null);
}

export function startChecker() {
  setInterval(clearToken, REFRESH_INTERVAL)
}
