
export const newStudentApplicationState = {
  firstName: "",
  lastName : "",
  schoolId: "",
  gradeLevel : "",
  birthday: "",
  currentSchoolName: "",
  currentSchoolAddress: "",
  currentSchoolPhone: "",
  currentSchoolFax: "",
  currentSchoolDistrict: "",
  notes:"",
  // primaryLanguage: "",
  languageQ1: "",
  languageQ2: "",
  languageQ3: "",
  languageQ4: "",
  languageQ5: "",
  housing1: "",
  housing2: "",
  housing3: "",
  housing4: "",
  housing5: "",
  housing6: "",
  housing7: "",
  housing8: "",
  housing9: "",
  specialEdQ1: "",
  specialEdQ2: "",
  specialEdQ3: "",
  specialEdQ4: "",
  specialEdQ5: "",
  specialEdQ6: "",
  file1:"",
  file2:"",
  file3:"",
  file4:"",
  file5:"",
  file1Loading: false,
  file2Loading: false,
  file3Loading: false,
  file4Loading: false,
  file5Loading: false,
  file1Error: false,
  file2Error: false,
  file3Error: false,
  file4Error: false,
  file5Error: false,
  siblingInfo: "",
  hasSibling: ""
}

export const newStudentApplication = {
  firstName: "",
  lastName : "",
  schoolId: "",
  gradeLevel : "",
  birthday: "",
  currentSchoolName: "",
  currentSchoolAddress: "",
  currentSchoolPhone: "",
  currentSchoolFax: "",
  currentSchoolDistrict: "",
  // primaryLanguage:"",
  languageQ1: "",
  languageQ2: "",
  languageQ3: null,
  languageQ4: null,
  languageQ5: "",
  specialEdQ1: "",
  specialEdQ2: "",
  specialEdQ3: "",
  specialEdQ4: "",
  specialEdQ5: "",
  specialEdQ6: "",
  housing1: null,
  housing2: null,
  housing3: null,
  housing4: null,
  housing5: null,
  housing6: null,
  housing7: null,
  housing8: null,
  housing9: null,
  notes: null,
  siblingInfo: "",
  hasSibling: ""
}

export const formApplicationState = {
  applicationId: "",
  parentLastName: '',
  parentFirstName: '',
  relation: '',
  email: '',
  pPhone: '',
  phoneType: '',
  emergencyFirstName: '',
  emergencyLastName: '',
  emergencyRelationship: '',
  secondaryPhone: '',
  secondaryPhoneType: '',
  address: '',
  city: '',
  state: '',
  postalCode: '',
  freeAndReduced: "",
  homeDistrictQ1: "",
  homeDistrictQ2: "",
  signature: "",
  student:
    {
      firstName: "",
      lastName : "",
      schoolYear: "",
      schoolId: "",
      gradeLevel : "",
      birthday: "",
      ethnicity: "",
      gender:"",
      currentSchoolName: "",
      currentSchoolAddress: "",
      currentSchoolPhone: "",
      currentSchoolFax: "",
      currentSchoolDistrict: "",
      notes:null,
      languageQ1: "",
      languageQ2: "",
      languageQ3: null,
      languageQ4: null,
      languageQ5: "",
      languageQ6: "",
      languageQ7: "370",
      languageQ8: "370",
      housing1: "",
      housing2: "",
      housing3: "",
      housing4: "",
      housing5: "",
      housing6: "",
      housing7: "",
      housing8: "",
      housing9: "",
      specialEdQ1: "",
      specialEdQ2: "",
      specialEdQ3: "",
      specialEdQ4: "",
      specialEdQ5: "",
      specialEdQ6: "",
      specialEdQ7: "",
      file1:{file:"", error: false, loading:false, fileFormValue:""},
      file2:{file:"", error: false, loading:false, fileFormValue:""},
      file3:{file:"", error: false, loading:false, fileFormValue:""},
      file4:{file:"", error: false, loading:false, fileFormValue:""},
      file5:{file:"", error: false, loading:false, fileFormValue:""},
      siblingInfo: "",
      hasSibling: ""
    }
}

export const languages = [
  {key:"370", value:"Select Language"},
  {key:"261", value:"Arabic"},
  {key:"250", value:"Avaricaus  Australian languages"},
  {key:"251", value:"Athapascan (Other)"},
  {key:"252", value:"Bable"},
  {key:"253", value:"Assamese"},
  {key:"254", value:"Arawak"},
  {key:"255", value:"Artificial (Other)"},
  {key:"256", value:"Arapaho"},
  {key:"257", value:"Mapuche"},
  {key:"258", value:"Armenian"},
  {key:"259", value:"Aragonese Spanish"},
  {key:"260", value:"Aramaic"},
  {key:"262", value:"Apache languages"},
  {key:"263", value:"English, Old (ca. 450-1100)"},
  {key:"264", value:"Amharic"},
  {key:"265", value:"Algonquian (Other)"},
  {key:"266", value:"Aleut"},
  {key:"267", value:"Albanian"},
  {key:"268", value:"Akkadian"},
  {key:"269", value:"Akan"},
  {key:"270", value:"Afrikaans"},
  {key:"271", value:"Afrihili (Artificial language)"},
  {key:"272", value:"Afroasiatic (Other)"},
  {key:"273", value:"Adygei"},
  {key:"274", value:"Adangme"},
  {key:"275", value:"Braj"},
  {key:"276", value:"Bosnian"},
  {key:"277", value:"Bantu (Other)"},
  {key:"278", value:"Siksika"},
  {key:"279", value:"Bislama"},
  {key:"280", value:"Edo"},
  {key:"281", value:"Bikol"},
  {key:"282", value:"Bihari"},
  {key:"283", value:"Bhojpuri"},
  {key:"284", value:"Berber (Other)"},
  {key:"285", value:"Bengali"},
  {key:"286", value:"Bemba"},
  {key:"287", value:"Belarusian"},
  {key:"288", value:"Beja"},
  {key:"289", value:"Baltic (Other)"},
  {key:"290", value:"Basa"},
  {key:"291", value:"Basque"},
  {key:"292", value:"Balinese"},
  {key:"293", value:"Bambara"},
  {key:"294", value:"Baluchi"},
  {key:"295", value:"Bashkir"},
  {key:"296", value:"Bamileke languages"},
  {key:"297", value:"Banda"},
  {key:"298", value:"Azerbaijani"},
  {key:"299", value:"Aymara"},
  {key:"300", value:"Awadhi"},
  {key:"301", value:"Avestan"},
  {key:"302", value:"Chuvash"},
  {key:"303", value:"Church Slavic"},
  {key:"304", value:"Cherokee"},
  {key:"305", value:"Chipewyan"},
  {key:"306", value:"Choctaw"},
  {key:"307", value:"Chinook jargon"},
  {key:"308", value:"Mari"},
  {key:"309", value:"Truk"},
  {key:"310", value:"Chinese"},
  {key:"311", value:"Chagatai"},
  {key:"312", value:"Chechen"},
  {key:"313", value:"Chibcha"},
  {key:"314", value:"Chamorro"},
  {key:"315", value:"Celtic (Other)"},
  {key:"316", value:"Cebuano"},
  {key:"317", value:"Caucasian (Other)"},
  {key:"318", value:"Catalan"},
  {key:"319", value:"Carib"},
  {key:"320", value:"Central American Indian (Other)"},
  {key:"321", value:"Caddo"},
  {key:"322", value:"Burmese"},
  {key:"323", value:"Bulgarian"},
  {key:"324", value:"Bugis"},
  {key:"325", value:"Buriat"},
  {key:"326", value:"Batak"},
  {key:"327", value:"Breton"},
  {key:"328", value:"Dutch"},
  {key:"329", value:"Dutch, Middle (ca. 1050-1350)"},
  {key:"330", value:"Duala"},
  {key:"331", value:"Dravidian (Other)"},
  {key:"332", value:"Dogri"},
  {key:"333", value:"Divehi"},
  {key:"334", value:"Dinka"},
  {key:"335", value:"Dogrib"},
  {key:"336", value:"Slave"},
  {key:"337", value:"Delaware"},
  {key:"338", value:"Dayak"},
  {key:"339", value:"Dargwa"},
  {key:"340", value:"Danish"},
  {key:"341", value:"Dakota"},
  {key:"342", value:"Czech"},
  {key:"343", value:"Cushitic (Other)"},
  {key:"344", value:"Creoles and Pidgins (Other)"},
  {key:"345", value:"Crimean Tatar"},
  {key:"346", value:"Cree"},
  {key:"347", value:"Creoles and Pidgins, Portuguese-based (Other)"},
  {key:"348", value:"Creoles and Pidgins, French-based (Other)"},
  {key:"349", value:"Creoles and Pidgins, English-based (Other)"},
  {key:"350", value:"Corsican"},
  {key:"351", value:"Cornish"},
  {key:"352", value:"Coptic"},
  {key:"353", value:"Chamic languages"},
  {key:"354", value:"Cheyenne"},
  {key:"355", value:"French, Old (ca. 842-1400)"},
  {key:"356", value:"French, Middle (ca. 1400-1600)"},
  {key:"357", value:"French"},
  {key:"358", value:"Fon"},
  {key:"471", value:"Kyrgyz"},
  {key:"359", value:"Finno-Ugrian (Other)"},
  {key:"360", value:"Finnish"},
  {key:"361", value:"Fijian"},
  {key:"362", value:"Fanti"},
  {key:"363", value:"Faroese"},
  {key:"364", value:"Fang"},
  {key:"365", value:"Ewondo"},
  {key:"366", value:"Ewe"},
  {key:"367", value:"Estonian"},
  {key:"368", value:"Esperanto"},
  {key:"369", value:"English, Middle (1100-1500)"},
  {key:"371", value:"Elamite"},
  {key:"372", value:"Ekajuk"},
  {key:"373", value:"Egyptian"},
  {key:"374", value:"Efik"},
  {key:"375", value:"Dzongkha"},
  {key:"376", value:"Dyula"},
  {key:"377", value:"Guarani"},
  {key:"378", value:"Greek, Modern (1453- )"},
  {key:"379", value:"Greek, Ancient (to 1453)"},
  {key:"380", value:"Grebo"},
  {key:"381", value:"Gothic"},
  {key:"382", value:"Gorontalo"},
  {key:"383", value:"Gondi"},
  {key:"384", value:"German, Old High (ca. 750-1050)"},
  {key:"385", value:"German, Middle High (ca. 1050-1500)"},
  {key:"386", value:"Manx"},
  {key:"387", value:"Galician"},
  {key:"388", value:"Irish"},
  {key:"389", value:"Scottish Gaelic"},
  {key:"390", value:"Gilbertese"},
  {key:"391", value:"Ethiopic"},
  {key:"392", value:"German"},
  {key:"393", value:"Georgian"},
  {key:"394", value:"Germanic (Other)"},
  {key:"395", value:"Gbaya"},
  {key:"396", value:"Gayo"},
  {key:"397", value:"Oromo"},
  {key:"398", value:"Gã"},
  {key:"399", value:"Friulian"},
  {key:"400", value:"Fula"},
  {key:"401", value:"Frisian"},
  {key:"402", value:"Interlingua (International Auxiliary Language Association)"},
  {key:"403", value:"Iloko"},
  {key:"404", value:"Interlingue"},
  {key:"405", value:"Inuktitut"},
  {key:"406", value:"Ijo"},
  {key:"407", value:"Sichuan Yi"},
  {key:"408", value:"Ido"},
  {key:"409", value:"Icelandic"},
  {key:"410", value:"Igbo"},
  {key:"411", value:"Iban"},
  {key:"412", value:"Hupa"},
  {key:"413", value:"Hungarian"},
  {key:"414", value:"Hiri Motu"},
  {key:"415", value:"Hmong"},
  {key:"416", value:"Hittite"},
  {key:"417", value:"Hindi"},
  {key:"418", value:"Himachali"},
  {key:"419", value:"Hiligaynon"},
  {key:"420", value:"Herero"},
  {key:"421", value:"Hebrew"},
  {key:"422", value:"Hawaiian"},
  {key:"423", value:"Hausa"},
  {key:"424", value:"Haitian French Creole"},
  {key:"425", value:"Haida"},
  {key:"426", value:"Gwich'in"},
  {key:"427", value:"Gujarati"},
  {key:"428", value:"Khasi"},
  {key:"429", value:"Kabardian"},
  {key:"430", value:"Kazakh"},
  {key:"431", value:"Kawi"},
  {key:"432", value:"Kanuri"},
  {key:"433", value:"Kashmiri"},
  {key:"434", value:"Karen"},
  {key:"435", value:"Kannada"},
  {key:"436", value:"Kamba"},
  {key:"437", value:"Kalâtdlisut"},
  {key:"438", value:"Kachin"},
  {key:"439", value:"Kabyle"},
  {key:"440", value:"Kara-Kalpak"},
  {key:"441", value:"Judeo-Arabic"},
  {key:"442", value:"Judeo-Persian"},
  {key:"443", value:"Japanese"},
  {key:"444", value:"Javanese"},
  {key:"445", value:"Italian"},
  {key:"446", value:"Iroquoian (Other)"},
  {key:"447", value:"Iranian (Other)"},
  {key:"448", value:"Inupiaq"},
  {key:"449", value:"Ingush"},
  {key:"450", value:"Indo-European (Other)"},
  {key:"451", value:"Indonesian"},
  {key:"452", value:"Indic (Other)"},
  {key:"453", value:"Latin"},
  {key:"454", value:"Lao"},
  {key:"455", value:"Lamba"},
  {key:"456", value:"Lahnda"},
  {key:"457", value:"Ladino"},
  {key:"458", value:"Kutenai"},
  {key:"459", value:"Kurdish"},
  {key:"460", value:"Kumyk"},
  {key:"461", value:"Kuanyama"},
  {key:"462", value:"Kurukh"},
  {key:"463", value:"Kru"},
  {key:"464", value:"Kpelle"},
  {key:"465", value:"Kusaie"},
  {key:"466", value:"Korean"},
  {key:"467", value:"Kongo"},
  {key:"468", value:"Komi"},
  {key:"469", value:"Konkani"},
  {key:"470", value:"Kimbundu"},
  {key:"472", value:"Kinyarwanda"},
  {key:"473", value:"Kikuyu"},
  {key:"474", value:"Khotanese"},
  {key:"475", value:"Khmer"},
  {key:"476", value:"Khoisan (Other)"},
  {key:"477", value:"Masai"},
  {key:"478", value:"Marathi"},
  {key:"479", value:"Austronesian (Other)"},
  {key:"480", value:"Maori"},
  {key:"481", value:"Mandingo"},
  {key:"482", value:"Malayalam"},
  {key:"483", value:"Makasar"},
  {key:"484", value:"Maithili"},
  {key:"485", value:"Marshallese"},
  {key:"486", value:"Magahi"},
  {key:"487", value:"Madurese"},
  {key:"488", value:"Macedonian"},
  {key:"489", value:"Lushai"},
  {key:"490", value:"Luo (Kenya and Tanzania)"},
  {key:"491", value:"Lunda"},
  {key:"492", value:"Luiseño"},
  {key:"493", value:"Ganda"},
  {key:"494", value:"Luba-Katanga"},
  {key:"495", value:"Luba-Lulua"},
  {key:"496", value:"Letzeburgesch"},
  {key:"497", value:"Lozi"},
  {key:"498", value:"Mongo-Nkundu"},
  {key:"499", value:"Lithuanian"},
  {key:"500", value:"Lingala"},
  {key:"501", value:"Limburgish"},
  {key:"502", value:"Lezgian"},
  {key:"503", value:"Latvian"},
  {key:"504", value:"Neapolitan Italian"},
  {key:"505", value:"North American Indian (Other)"},
  {key:"506", value:"Nahuatl"},
  {key:"507", value:"Mayan languages"},
  {key:"508", value:"Marwari"},
  {key:"509", value:"Creek"},
  {key:"510", value:"Munda (Other)"},
  {key:"511", value:"Multiple languages"},
  {key:"512", value:"Mooré"},
  {key:"513", value:"Mongolian"},
  {key:"514", value:"Moldavian"},
  {key:"515", value:"Mohawk"},
  {key:"516", value:"Manobo languages"},
  {key:"517", value:"Manipuri"},
  {key:"518", value:"Manchu"},
  {key:"519", value:"Maltese"},
  {key:"520", value:"Malagasy"},
  {key:"521", value:"Mon-Khmer (Other)"},
  {key:"522", value:"Miscellaneous languages"},
  {key:"523", value:"Minangkabau"},
  {key:"524", value:"Micmac"},
  {key:"525", value:"Irish, Middle (ca. 1100-1550)"},
  {key:"526", value:"Mende"},
  {key:"527", value:"Mandar"},
  {key:"528", value:"Malay"},
  {key:"529", value:"Oromo"},
  {key:"530", value:"Oriya"},
  {key:"531", value:"Ojibwa"},
  {key:"532", value:"Occitan (post-1500)"},
  {key:"533", value:"Nzima"},
  {key:"534", value:"Nyoro"},
  {key:"535", value:"Nyankole"},
  {key:"536", value:"Nyamwezi"},
  {key:"537", value:"Nyanja"},
  {key:"538", value:"Nubian languages"},
  {key:"539", value:"Northern Sotho"},
  {key:"540", value:"Norwegian"},
  {key:"541", value:"Old Norse"},
  {key:"542", value:"Nogai"},
  {key:"543", value:"Norwegian (Bokmål)"},
  {key:"544", value:"Norwegian (Nynorsk)"},
  {key:"545", value:"Niuean"},
  {key:"546", value:"Niger-Kordofanian (Other)"},
  {key:"547", value:"Nias"},
  {key:"548", value:"Newari"},
  {key:"549", value:"Nepali"},
  {key:"550", value:"Low German"},
  {key:"551", value:"Ndonga"},
  {key:"552", value:"Ndebele (Zimbabwe)"},
  {key:"553", value:"Ndebele (South Africa)"},
  {key:"554", value:"Navajo"},
  {key:"555", value:"Nauru"},
  {key:"556", value:"Romance (Other)"},
  {key:"557", value:"Rarotongan"},
  {key:"558", value:"Rapanui"},
  {key:"559", value:"Rajasthani"},
  {key:"560", value:"Quechua"},
  {key:"561", value:"Pushto"},
  {key:"562", value:"Provençal (to 1500)"},
  {key:"563", value:"Prakrit languages"},
  {key:"564", value:"Portuguese"},
  {key:"565", value:"Ponape"},
  {key:"566", value:"Polish"},
  {key:"567", value:"Pali"},
  {key:"568", value:"Phoenician"},
  {key:"569", value:"Philippine (Other)"},
  {key:"570", value:"Persian"},
  {key:"571", value:"Old Persian (ca. 600-400 B.C.)"},
  {key:"572", value:"Palauan"},
  {key:"573", value:"Papiamento"},
  {key:"574", value:"Panjabi"},
  {key:"575", value:"Pampanga"},
  {key:"576", value:"Pahlavi"},
  {key:"577", value:"Pangasinan"},
  {key:"578", value:"Papuan (Other)"},
  {key:"579", value:"Otomian languages"},
  {key:"580", value:"Turkish, Ottoman"},
  {key:"581", value:"Ossetic"},
  {key:"582", value:"Osage"},
  {key:"583", value:"Siouan (Other)"},
  {key:"584", value:"Sinhalese"},
  {key:"585", value:"Sidamo"},
  {key:"586", value:"Shan"},
  {key:"587", value:"Sign languages"},
  {key:"588", value:"Irish, Old (to 1100)"},
  {key:"589", value:"Semitic (Other)"},
  {key:"590", value:"Selkup"},
  {key:"591", value:"Croatian"},
  {key:"592", value:"Scots"},
  {key:"593", value:"Serbian"},
  {key:"594", value:"Santali"},
  {key:"595", value:"Sasak"},
  {key:"596", value:"Sanskrit"},
  {key:"597", value:"Samaritan Aramaic"},
  {key:"598", value:"Salishan languages"},
  {key:"599", value:"South American Indian (Other)"},
  {key:"600", value:"Yakut"},
  {key:"601", value:"Sango (Ubangi Creole)"},
  {key:"602", value:"Sandawe"},
  {key:"603", value:"Russian"},
  {key:"604", value:"Rundi"},
  {key:"605", value:"Romanian"},
  {key:"606", value:"Romani"},
  {key:"607", value:"Raeto-Romance"},
  {key:"608", value:"Sundanese"},
  {key:"609", value:"Sukuma"},
  {key:"610", value:"Swazi"},
  {key:"611", value:"Nilo-Saharan (Other)"},
  {key:"612", value:"Serer"},
  {key:"613", value:"Sardinian"},
  {key:"614", value:"Spanish"},
  {key:"615", value:"Sotho"},
  {key:"616", value:"Songhai"},
  {key:"617", value:"Somali"},
  {key:"618", value:"Sogdian"},
  {key:"619", value:"Soninke"},
  {key:"620", value:"Sindhi"},
  {key:"621", value:"Shona"},
  {key:"622", value:"Skolt Sami"},
  {key:"623", value:"Samoan"},
  {key:"624", value:"Inari Sami"},
  {key:"625", value:"Lule Sami"},
  {key:"626", value:"Sami"},
  {key:"627", value:"Northern Sami"},
  {key:"628", value:"Southern Sami"},
  {key:"629", value:"Slovenian"},
  {key:"630", value:"Slovak"},
  {key:"631", value:"Slavic (Other)"},
  {key:"632", value:"Sino-Tibetan (Other)"},
  {key:"633", value:"Tamashek"},
  {key:"634", value:"Tlingit"},
  {key:"635", value:"Tokelauan"},
  {key:"636", value:"Tiv"},
  {key:"637", value:"Tigrinya"},
  {key:"638", value:"Tigré"},
  {key:"639", value:"Tibetan"},
  {key:"640", value:"Thai"},
  {key:"641", value:"Tagalog"},
  {key:"642", value:"Tajik"},
  {key:"643", value:"Tetum"},
  {key:"644", value:"Terena"},
  {key:"645", value:"Temne"},
  {key:"646", value:"Telugu"},
  {key:"647", value:"Tatar"},
  {key:"648", value:"Tamil"},
  {key:"649", value:"Tai (Other)"},
  {key:"650", value:"Tahitian"},
  {key:"651", value:"Syriac"},
  {key:"652", value:"Swedish"},
  {key:"653", value:"Swahili"},
  {key:"654", value:"Sumerian"},
  {key:"655", value:"Susu"},
  {key:"656", value:"Vietnamese"},
  {key:"657", value:"Venda"},
  {key:"658", value:"Vai"},
  {key:"659", value:"Uzbek"},
  {key:"660", value:"Urdu"},
  {key:"661", value:"Undetermined"},
  {key:"662", value:"Umbundu"},
  {key:"663", value:"Ukrainian"},
  {key:"664", value:"Uighur"},
  {key:"665", value:"Ugaritic"},
  {key:"666", value:"Udmurt"},
  {key:"667", value:"Tuvinian"},
  {key:"668", value:"Twi"},
  {key:"669", value:"Tuvaluan"},
  {key:"670", value:"Altaic (Other)"},
  {key:"671", value:"Turkish"},
  {key:"672", value:"Tupi languages"},
  {key:"673", value:"Tumbuka"},
  {key:"674", value:"Turkmen"},
  {key:"675", value:"Tsonga"},
  {key:"676", value:"Tswana"},
  {key:"677", value:"Tsimshian"},
  {key:"678", value:"Tok Pisin"},
  {key:"679", value:"Tongan"},
  {key:"680", value:"Tonga (Nyasa)"},
  {key:"681", value:"Zuni"},
  {key:"682", value:"Zulu"},
  {key:"683", value:"Zande"},
  {key:"684", value:"Zhuang"},
  {key:"685", value:"Zenaga"},
  {key:"686", value:"Zapotec"},
  {key:"687", value:"Yupik languages"},
  {key:"688", value:"Yoruba"},
  {key:"689", value:"Yiddish"},
  {key:"690", value:"Yapese"},
  {key:"691", value:"Yao (Africa)"},
  {key:"692", value:"Xhosa"},
  {key:"693", value:"Kalmyk"},
  {key:"694", value:"Wolof"},
  {key:"695", value:"Walloon"},
  {key:"696", value:"Sorbian languages"},
  {key:"697", value:"Welsh"},
  {key:"698", value:"Washo"},
  {key:"699", value:"Waray"},
  {key:"700", value:"Walamo"},
  {key:"701", value:"Wakashan languages"},
  {key:"702", value:"Votic"},
  {key:"703", value:"Volapük"}
]






