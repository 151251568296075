
import {ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import AppConfig from "./config";

const httpLink = createHttpLink({
  uri: AppConfig.apolloServerURI,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  return {
    headers: {
      ...headers,
      authorization: localStorage.getItem('id_token')
    }
  }
});

const client = new ApolloClient({
  // Provide required constructor fields
  cache: new InMemoryCache(
    {
      resultCaching: true,
    }
  ),
  link: authLink.concat(httpLink),
  // Provide some optional constructor fields
  name: 'react-web-client',
  version: '1.3',
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export default client
