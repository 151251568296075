import { gql } from "@apollo/client";


export const REGISTER_QUERY = gql`
    mutation($input:RegistererInput) {
      register(input: $input) {
        id
        formId
        email
      }
    }
  `;

export const UPDATE_STUDENT_APPLICATION_QUERY = gql`
    mutation($input:UpdateRegistererInput) {
      updateStudentApplication(input: $input) {
        id
        formId
      }
    }
  `;

export const ADD_STUDENT_APPLICATION_QUERY = gql`
    mutation($input:AddStudentsRegistererInput) {
      addStudentApplication(input: $input) {
        id
        formId
      }
    }
  `;

export const CHECK_REGISTRATION_QUERY = gql`
  query GetRegistrationByIdAndEmail($getRegistrationByIdAndEmailId: String, $email: String) {
    getRegistrationByIdAndEmail(id: $getRegistrationByIdAndEmailId, email: $email) {
      id
      formId
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation($getRegistrationByIdAndEmailId: String, $email: String) {
      login(id: $getRegistrationByIdAndEmailId, email: $email) {
        id
        formId
      }
    }
`;

export const SEND_FORGET_PASSWORD = gql`
  mutation($email: String) {
      forgetPassword(email: $email) 
    }
`;

export const GET_APPLICATION_UPDATES_QUERY = gql`
  query GetApplicationUpdates($getRegistrationByIdAndEmailId: String) {
    getApplicationUpdates(id: $getRegistrationByIdAndEmailId) {
      id
      formId
      email
      firstName
      lastName
      relationship
      primaryContactPhone
      phoneType
      emergencyFirstName
      emergencyLastName
      emergencyRelationship
      secondaryPhone
      secondaryPhoneType
      address
      city
      state
      postalCode     
      freeAndReduced
      homeDistrictQ1
      homeDistrictQ2
      applications {
      id          
      firstName
      lastName
      gradeLevel
      birthDate
      ethnicity
      status
      gender
      schoolYear
      currentSchoolName
      currentSchoolAddress
      currentSchoolPhone
      currentSchoolFax
      currentSchoolDistrict
      languageQ1
      languageQ2
      languageQ3
      languageQ4
      languageQ5
      languageQ7
      languageQ8
      specialEdQ1
      specialEdQ2
      specialEdQ3
      specialEdQ4
      specialEdQ5
      specialEdQ6
      specialEdQ7
      housing1
      housing2
      housing3
      housing4
      housing5
      housing6
      housing7
      housing8
      housing9
      siblingInfo
      hasSibling
      
      applicationsNotes {
        created_at
        from
        notes
        id
      }
      birthCertificateFilePath
      grades1FilePath  
      schoolId  
      school {
        name
      }
    }
      status      
    }
  }
`;
