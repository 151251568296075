/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React, {Suspense, useEffect} from 'react';
import { useRoutes } from 'react-router-dom';
import {useSelector} from 'react-redux';
import { GEELoggedInRoutes, GEENotLoggedInRoutes } from './routes/Router';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Loader from './layouts/loader/Loader';
import {getToken, startChecker} from "./helper/authHelper";


const App = () => {
  const notLoggedInRouting = useRoutes(GEENotLoggedInRoutes);
  const loggedInRouting = useRoutes(GEELoggedInRoutes);
  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);
  const token = getToken()

  useEffect(() => {
    startChecker();
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <div
        className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
        dir={direction ? 'rtl' : 'ltr'}
      >
        <ThemeSelector />
        { (!token) ? notLoggedInRouting : loggedInRouting }
      </div>
    </Suspense>
  );
};

export default App;
