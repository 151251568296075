//import zipcodes from "zipcodes";
import * as Yup from "yup";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
//const FILE_SIZE = 5 * 1024 * 1024

export const validationSchema = Yup.object().shape({
  email: Yup.string().email('Email is invalid').required('Email is required'),
  parentLastName: Yup.string().required('Parent Last Name is required'),
  parentFirstName: Yup.string().required('Parent First Name is required'),
  pPhone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Primary phone is required').length(10),
  oPhone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  address: Yup.string().required('Address is required'),
  city: Yup.string().matches(/^[a-zA-Z ]*$/).required('City is required'),
  state: Yup.string().matches(/^[a-zA-Z ]*$/).required('State is required').length(2),
  postalCode: Yup.string().matches(/^\d+$/, 'Only numbers').required('Postal Code is required'),
  relation: Yup.string().required('Relationship is required'),
  freeAndReduced: Yup.string().required('Box must be checked'),
  homeDistrictQ1:Yup.string().required('Box must be checked'),
  homeDistrictQ2:Yup.string().required('Field is required'),

  // signature:Yup.string().required('Field is required'),
  // signature: Yup.string().required()
  //   .matches(/^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
  //     "Signature required"
  //   ),

  student: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string().required('Student First Name is required'),
        lastName: Yup.string().required('Student Last Name is required'),
        birthday: Yup.string().required('Birthday is required'),
        gradeLevel: Yup.string().required('Grade Level is required'),
        // primaryLanguage: Yup.string().required('Primary Language is required'),
        languageQ1: Yup.string().required('Field is required'),
        languageQ2: Yup.string().required('Field is required'),
        specialEdQ1: Yup.string().required('Field is required'),
        specialEdQ2: Yup.string().required('Field is required'),
        specialEdQ3: Yup.string().required('Field is required'),
        specialEdQ4: Yup.string().required('Field is required'),
        specialEdQ5: Yup.string().required('Field is required'),
        specialEdQ6: Yup.string().required('Field is required'),
        schoolId: Yup.string().required('School is required'),
        // file1: Yup.object().shape({
        //     files: Yup.array()//.required('file is required')
        //       .nullable()
        //       .test({
        //         name: 'fileSize',
        //         message: `File must be under 5M`,
        //         test: files => {
        //           if (files) {
        //             alert(files.size)
        //             return files[0].size <= FILE_SIZE
        //           }
        //           return true
        //         }
        //       })
        // }).nullable(),
        // file2: Yup.mixed()//.required('file is required')
        //   .nullable()
        //   .test({
        //     name: 'fileSize',
        //     message: `File must be under 5M`,
        //     test: files => {
        //       if (files) {
        //         return files[0].size <= FILE_SIZE
        //       }
        //       return true
        //     }
        //   }),
        // file3: Yup.mixed()//.required('file is required')
        //   .nullable()
        //   .test({
        //     name: 'fileSize',
        //     message: `File must be under 5M`,
        //     test: files => {
        //       if (files) {
        //         return files[0].size <= FILE_SIZE
        //       }
        //       return true
        //     }
        //   }),
        // file4: Yup.mixed()//.required('file is required')
        //   .nullable()
        //   .test({
        //     name: 'fileSize',
        //     message: `File must be under 5M`,
        //     test: files => {
        //       if (files) {
        //         return files[0].size <= FILE_SIZE
        //       }
        //       return true
        //     }
        //   })
      })
    ).required()

  // registrationId: Yup.string()
  //   .required('Registration ID is required'),
});


export const validationSchemaStep1 = Yup.object().shape({
  email: Yup.string().email('Email is invalid').required('Email is required'),
  parentLastName: Yup.string().required('Parent Last Name is required'),
  parentFirstName: Yup.string().required('Parent First Name is required'),
  pPhone: Yup.string().matches(/^\d+$/, 'Only numbers', 'Phone is invalid, only digits allowed').required('Phone is required').length(10, 'Phone should be 10 digits long'),
  phoneType: Yup.string().required('Phone type is required'),
  emergencyFirstName: Yup.string().required('Emergency contact First Name is required'),
  emergencyLastName: Yup.string().required('Emergency contact Last Name is required'),
  emergencyRelationship: Yup.string().required('Emergency contact Relationship is required'),
  secondaryPhone: Yup.string().notOneOf([Yup.ref('pPhone'), null], "Emergency phone should be different than phone!").matches(/^\d+$/, 'Only numbers', 'Emergency phone is invalid, only digits allowed').required('Emergency phone is required').length(10, 'Emergency phone should be 10 digits long'),
  secondaryPhoneType: Yup.string().required('Emergency phone type is required'),
  address: Yup.string().required('Address is required'),
  city: Yup.string().matches(/^[a-zA-Z ]*$/, 'City is invalid, no digits allowed').required('City is required'),
  state: Yup.string().matches(/^[a-zA-Z ]*$/, 'State is invalid, no digits allowed').required('State is required').length(2, 'State should be 2 characters long'),
  postalCode: Yup.string().matches(/^\d+$/, 'Postal code invalid, only digits allowed').required('Postal Code is required'),
  relation: Yup.string().required('Relationship is required'),
  freeAndReduced: Yup.string().required('Box must be checked')
  // homeDistrictQ1:Yup.string().required('Field is required'),
  // homeDistrictQ2:Yup.string().required('Field is required')
});


//postalCode: Yup.string().matches(/^\d+$/, 'Postal code invalid, only digits allowed').required('Postal Code is required').length(5, 'Postal Code should be 5 digits long')
//     .test(
//       'Postal code does not match city',
//       'Postal code does not match city',
//       function() {
//         return zipcodes.lookup(this.parent.postalCode)?.state === this.parent.state
//       }
//     ),
// const minBirthday = new Date();
// minBirthday.setFullYear( minBirthday.getFullYear() - 20 )
// console.log(`date ${minBirthday}`);
//
// const maxBirthday = new Date();
// maxBirthday.setFullYear( minBirthday.getFullYear() - 2 )
// console.log(`date ${minBirthday}`);
function testSchoolGrade(gradeLevel, schoolId) {
  switch (schoolId) {
    case "30000017": if (gradeLevel === '9TH' || gradeLevel === '10TH' || gradeLevel === '11TH' || gradeLevel === '12TH') return false; break;
    case "30000005":  if (gradeLevel === '8TH' || gradeLevel === '9TH' || gradeLevel === '10TH' || gradeLevel === '11TH' || gradeLevel === '12TH') return false; break;
    case "30000028":  if (gradeLevel === '7TH' || gradeLevel === '8TH' || gradeLevel === '9TH' || gradeLevel === '10TH' || gradeLevel === '11TH' || gradeLevel === '12TH') return false; break;
    case "30000079":
    case "533":
    case "3898":  if (gradeLevel === '6TH' || gradeLevel === '7TH' || gradeLevel === '8TH' || gradeLevel === '9TH' || gradeLevel === '10TH' || gradeLevel === '11TH' || gradeLevel === '12TH') return false; break;
    case "30000009":   if (gradeLevel !== '6TH' && gradeLevel !== '7TH' && gradeLevel !== '8TH')  return false; break;
    case "1698":  if (gradeLevel === '6TH' || gradeLevel === '7TH' || gradeLevel === '8TH')  return false; break;
    case "3899": if (gradeLevel !== '6TH' && gradeLevel !== '7TH' && gradeLevel !== '8TH' && gradeLevel !== '9TH' && gradeLevel !== '10TH' && gradeLevel !== '11TH' && gradeLevel !== '12TH') return false; break;
    case "30000371":  if (gradeLevel === '9TH' || gradeLevel === '10TH' || gradeLevel === '11TH' || gradeLevel === '12TH') return false; break;
    default: return true
  }
  return true;
}

function testSchoolYear(schoolYear, schoolId) {
  switch (schoolId) {
    case "30000371":  if (schoolYear === '2023') return false; break;
    default: return true
  }
  return true;
}

export const validationSchemaStep2 = Yup.object().shape({
  student:
      Yup.object().shape({
        firstName: Yup.string().required('Student First Name is required'),
        lastName: Yup.string().required('Student Last Name is required'),
        birthday: Yup.date().required('Birthday is required'),
          // .min(minBirthday, 'Student can not be older than 20 years')
          // .max(maxBirthday, 'Student can not be younger than 2 years'),
        ethnicity: Yup.string().required('Ethnicity is required'),
        gradeLevel: Yup.string().required('Grade Level is required')
          .test(
          'Grade level not valid',
          'School does not have that grade level',
          function() {
            return testSchoolGrade(this.parent.gradeLevel, this.parent.schoolId)
          }
        ),
        schoolId: Yup.string().required('School is required'),
        schoolYear: Yup.string().required('School Year is required')
          .test(
            'School Year not valid',
            'Enrollment is not open for this school year',
            function() {
              return testSchoolYear(this.parent.schoolYear, this.parent.schoolId)
            }
        ),
        gender: Yup.string().required('Gender is required'),
        currentSchoolName: Yup.string().when('gradeLevel', {
          is: (gradeLevel) => gradeLevel !== 'KG',
          then: Yup.string().required('Current school name is required')
        }),
        currentSchoolAddress: Yup.string().when('gradeLevel', {
          is: (gradeLevel) => gradeLevel !== 'KG',
          then: Yup.string().required('Current school address is required')
        }),
        currentSchoolPhone: Yup.string().matches(/^\d+$/, 'Field is invalid, only digits allowed').nullable(true),
        currentSchoolFax: Yup.string().matches(/^\d+$/, 'Field is invalid, only digits allowed').nullable(true),
        hasSibling: Yup.string().required('Field is required'),
        siblingInfo: Yup.string().when('hasSibling', {
          is: (hasSibling) => hasSibling === 'Yes',
          then: Yup.string().required('Field is required')
        }),
      }).required()
});

export const validationSchemaStep3 = Yup.object().shape({
  student:
      Yup.object().shape({
        languageQ1: Yup.string().required('Field is required'),
        languageQ2: Yup.string().required('Field is required'),
        languageQ6: Yup.string().required('Field is required'),
        languageQ7: Yup.string().required('Field is required'),
        languageQ8: Yup.string().required('Field is required'),
        languageQ4: Yup.string().matches(/^\d+$/, 'Field is invalid, only digits allowed').nullable(true)
      }).required()
});

export const validationSchemaStep5 = Yup.object().shape({
  student:
      Yup.object().shape({
        specialEdQ1: Yup.string().required('Field is required'),
        specialEdQ2: Yup.string().required('Field is required'),
        specialEdQ3: Yup.string().required('Field is required'),
        specialEdQ4: Yup.string().required('Field is required'),
        specialEdQ5: Yup.string().required('Field is required'),
        specialEdQ6: Yup.string().required('Field is required'),
        specialEdQ7: Yup.string().required('Field is required'),
      }).required()
});


export const validationSchemaStep6 = Yup.object().shape({
  student: Yup.array()
    .of(
      Yup.object().shape({
        // file1: Yup.object().shape({
        //       files: Yup.array()//.required('file is required')
        //         .nullable()
        //         .test({
        //           name: 'fileSize',
        //           message: `File must be under 5M`,
        //           test: files => {
        //             if (files) {
        //               return files[0].size <= 5
        //             }
        //             return true
        //           }
        //         })
        //   }).nullable(),
      })
    ).required()
});
