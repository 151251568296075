import React from 'react';
import {Button, Container, Row, Col, CardBody, CardTitle, FormGroup, Label} from 'reactstrap';
import {Formik, Form, Field} from 'formik';
import PropTypes from "prop-types";


const Step4 = ({ nextStep, values, copyState, prevStep }) => {
  
  return (
    <div >
      <Container fluid >
        <Row className="justify-content-center align-items-center">
          
                <Formik
                  initialValues={values}


                  onSubmit={(fields) => {

                    console.log(fields);

                    copyState(prevState => ({
                      ...prevState,
                      ...fields
                    }));


                    nextStep();
                  }}

                  render={() => (
                    <Form>
                      <Col md="12">
                        <CardBody className="bg-light">
                          <CardTitle tag="h4" className="mb-0">
                            Student Housing Information
                          </CardTitle>
                        </CardBody>

                        <div className="mb-3 p-3 row">
                          <div className="form-control">
                            <Label htmlFor="name" className="col-sm-12 col-form-label">
                              Current Housing Status (please check what applies):
                            </Label>
                            <FormGroup>
                              <Label>
                                <Field type="checkbox" name="student.housing1"/>
                                &nbsp;&nbsp;Owner-occupied home
                              </Label>
                            </FormGroup>
                            <FormGroup>
                              <Label>
                                <Field type="checkbox" name="student.housing2"/>
                                &nbsp;&nbsp;Rental unit
                              </Label>
                            </FormGroup>
                            <FormGroup>
                              <Label>
                                <Field type="checkbox" name="student.housing3"/>
                                &nbsp;&nbsp;Emergency shelter or transitional housing
                              </Label>
                            </FormGroup>
                            <FormGroup>
                              <Label>
                                <Field type="checkbox" name="student.housing4"/>
                                &nbsp;&nbsp;Motel/hotel
                              </Label>
                            </FormGroup>
                            <FormGroup>
                              <Label>
                                <Field type="checkbox" name="student.housing5"/>
                                &nbsp;&nbsp;Campground
                              </Label>
                            </FormGroup>
                            <FormGroup>
                              <Label>
                                <Field type="checkbox" name="student.housing6"/>
                                &nbsp;&nbsp;Public or private place not designed for or ordinarily used as regular sleeping accommodation for humans, including cars, parks, public spaces, abandoned buildings, substandard housing, or bus or train stations
                              </Label>
                            </FormGroup>
                            <FormGroup>
                              <Label>
                                <Field type="checkbox" name="student.housing7"/>
                                &nbsp;&nbsp;Foster care placement for 6 months or less
                              </Label>
                            </FormGroup>
                            <FormGroup>
                              <Label>
                                <Field type="checkbox" name="student.housing8"/>
                                &nbsp;&nbsp;Long-term, stable, cooperative living arrangement
                              </Label>
                            </FormGroup>
                            <FormGroup>
                              <Label>
                                <Field type="checkbox" name="student.housing9"/>
                                &nbsp;&nbsp;Temporary shared housing with friends, family or others
                              </Label>
                            </FormGroup>



                          </div>
                        </div>

                        <CardBody className="border-top gap-2 d-flex align-items-center">
                          <FormGroup>
                            <Button type="button" className="btn btn-geePrimary ms-2" onClick={prevStep}>
                              Back
                            </Button>
                            <Button type="submit" className="btn btn-success ms-2">
                              Next
                            </Button>
                          </FormGroup>
                        </CardBody>
                      </Col>
                    </Form>
                  )}
                />
        </Row>
      </Container>
    </div>
  );
};

Step4.propTypes = {
  nextStep: PropTypes.any,
  values:PropTypes.any,
  copyState:PropTypes.any,
  prevStep: PropTypes.any
};

export default Step4;

