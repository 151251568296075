import React from 'react';
import {Button, Container, Row, Col, CardBody, CardTitle, FormGroup, Label, Input} from 'reactstrap';
import PropTypes from "prop-types";
import FormSignature from "../helpers/Signature";


const Step7 = ({ nextStep, values, copyState, prevStep }) => {
  const [signatureURL, setSignatureURL] = React.useState(values.signature)
  const [notes] = React.useState(values.student.notes)

  const handleInputData = (e) => {
    // input value from the form
    const {value } = e.target;
    copyState(prevState => ({
      ...prevState,
      student: {
        ...prevState.student,
        'notes': value
      }
    }));
  }

  const save = () => {
    // input value from the form
    if (signatureURL === "") {
      return;
    }
    copyState(prevState => ({
      ...prevState,
      'signature': signatureURL
    }));

    nextStep();
  }

  return (
    <div >
      <Container fluid >
        <Row className="justify-content-center align-items-center">

                  <Col md="12">
                    <CardBody className="bg-light">
                      <CardTitle tag="h4" className="mb-0">
                        Sign and Submit
                      </CardTitle>
                    </CardBody>

                    <CardBody>
                      <div className="mb-3 row">
                      <div className="mb-3 row">
                        <Label htmlFor="Notes" className="col-sm-2 col-form-label">
                          Additional Notes
                        </Label>
                        <div className="col-sm-10">
                          <div className="row">
                            <div className="col-sm-12">
                              <FormGroup>
                                <Input
                                  placeholder="Notes"
                                  name={notes}
                                  type="text"
                                  className="form-control"
                                  onChange={handleInputData}
                                />
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                      </div>

                      <FormSignature setSignatureURL={setSignatureURL} signatureURL={signatureURL} copyValues={copyState}/>
                      </div>
                    </CardBody>

                    <CardBody className="border-top gap-2 d-flex align-items-center">
                    <FormGroup>
                      <Button type="button" className="btn btn-geePrimary ms-2" onClick={prevStep}>
                        Back
                      </Button>
                      <Button type="button" className="btn btn-success ms-2" onClick={save}>
                        Submit Application
                      </Button>
                    </FormGroup>
                  </CardBody>
                  </Col>



        </Row>
      </Container>
    </div>
  );
};

Step7.propTypes = {
  nextStep: PropTypes.any,
  values:PropTypes.any,
  copyState:PropTypes.any,
  prevStep: PropTypes.any
};
export default Step7;

