import React, {useRef} from 'react';
import {
  Button,
  CardBody,
  FormGroup,
  InputGroup, Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import SignatureCanvas from "react-signature-canvas";
import PropTypes from "prop-types";


const FormSignature = ({signatureURL, setSignatureURL, copyValues}) => {

  const [signatureURLError, setSignatureURLError] = React.useState(true)
  const [open, setOpen] = React.useState(false);
  const ref = useRef({});


  const create = () => {
    const URL = ref.current.getTrimmedCanvas().toDataURL("image/png");
    if (URL !== 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC') {
      setSignatureURL(URL);
      setSignatureURLError(false);
      copyValues(prevState => ({
        ...prevState,
        'signature': URL
      }));

    }
    setOpen(false);
    return URL;
  };

  const clear = () => {
    ref.current.clear();
    setSignatureURL("");
    setSignatureURLError(true);
  }

  return (
    <CardBody>
      <div className="mb-3 row">
        <div className="mb-3 row">
          <div className={` mb-3 row form-control${
            signatureURLError? ' is-invalid' : ''}`}>
          <Label htmlFor="Notes" className="col-sm-2 col-form-label">
            Signature
          </Label>

          <div className="col-sm-10">
            <div className="row">
              <div className="col-sm-12">
                <FormGroup>
                  {signatureURL !== "" &&
                    <img src={signatureURL} alt="signature" className="signature" />}

                </FormGroup>
              </div>


            </div>
          </div>

          <div className="col-sm-9">
          <Button onClick={() => setOpen(true)}>Create Signature</Button>
          </div>
          </div>
          {signatureURLError.error &&
            <div className="invalid-feedback">You need to sign</div>}
        </div>
        <div className="col-sm-9">

          <Modal isOpen={open}>
            <ModalHeader toggle={() => setOpen(false)}>Create Signature</ModalHeader>

              <ModalBody>
                <InputGroup>
                  <div className={` mb-3 row form-control${
                    signatureURLError? ' is-invalid' : ''}`}>
                    <SignatureCanvas

                      penColor="black"
                      canvasProps={{className: "sigCanvas"}}
                      ref={ref}
                      // onEnd={create}
                    />

                    {signatureURLError.error &&
                      <div className="invalid-feedback">You need to sign</div>}
                  </div>
                </InputGroup>
              </ModalBody>
              <ModalFooter>
                <FormGroup>
                  <Button type="button" onClick={clear}>Clear Signature</Button>
                  &nbsp;&nbsp;
                  <Button type="button" className="create" onClick={create}>Sign Application</Button>
                </FormGroup>
              </ModalFooter>

          </Modal>


        </div>

      </div>
    </CardBody>
  )
}

FormSignature.propTypes = {
  signatureURL: PropTypes.any,
  setSignatureURL: PropTypes.any,
  copyValues: PropTypes.any
};

export default FormSignature;

