import React from 'react';

import LoginFormLogo from '../../assets/images/gee/GeeLoginPageLogo.png';

const LoginLogo = () => {

  return (
    <div className="p-4 d-flex justify-content-center gap-2">
      <img src={LoginFormLogo} alt="" width={340}   />
    </div>
  );
};

export default LoginLogo;
