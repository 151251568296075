import React from 'react';
import {
  Button,
  Container,
  Row,
  Col,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  InputGroup,
  Input,
  Spinner
} from 'reactstrap';
import PropTypes from "prop-types";
import {handleFileChange1} from "../helpers/FileUploadHelper";


const Step6 = ({ nextStep, copyState, prevStep, values }) => {

  const [file1, setFile1] = React.useState(values.student.file1);
  const [file2, setFile2] = React.useState(values.student.file2);
  const [file3, setFile3] = React.useState(values.student.file3);
  const [file4, setFile4] = React.useState(values.student.file4);
  const [file5, setFile5] = React.useState(values.student.file5);
  // Create a reference to the hidden file input element
  const hiddenFileInput1 = React.useRef(null);
  const hiddenFileInput2 = React.useRef(null);
  const hiddenFileInput3 = React.useRef(null);
  const hiddenFileInput4 = React.useRef(null);
  const hiddenFileInput5 = React.useRef(null);

  const submitForm = () => {
    nextStep();
  }

  const handleClick1 = () => {
    hiddenFileInput1.current.click();
  };
  const handleClick2 = () => {
    hiddenFileInput2.current.click();
  };
  const handleClick3 = () => {
    hiddenFileInput3.current.click();
  };
  const handleClick4 = () => {
    hiddenFileInput4.current.click();
  };
  const handleClick5 = () => {
    hiddenFileInput5.current.click();
  };

  return (
    <div >
      <Container fluid >
        <Row className="justify-content-center align-items-center">

          <Col md="12">
            <CardBody className="bg-light">
              <CardTitle tag="h4" className="mb-0">
                File Attachments
              </CardTitle>
            </CardBody>

            <div className="mb-3 p-3 row">
            <div className="mb-3 row">
              <Label htmlFor="birthCertificate" className="col-sm-2 col-form-label" >
                Birth Certificate
              </Label>
              <div className="col-sm-9">
                <InputGroup>
                  <Input value={file1.fileFormValue}
                         className={`form-control${
                           file1.error ? ' is-invalid' : ''
                         }`}
                  />
                <Button onClick={handleClick1} className="btn btn-success ">
                  Upload a file
                </Button>
                  {file1.error &&
                    <div className="invalid-feedback">File size too big</div>}
                <input
                  style={{display: 'none'}}
                  accept="image/png, image/jpeg, application/pdf, image/HEIC"
                  ref={hiddenFileInput1}
                  id="file1"
                  type="file"
                  onChange={e => {handleFileChange1(copyState, setFile1)(e)}}
                />

                </InputGroup>
              </div>
              {file1.loading && <Spinner className="me-2" type="grow" color="primary" />}
            </div>

            <div className="mb-3 row">
              <Label htmlFor="reportCard" className="col-sm-2 col-form-label" >
                Report Card / Transcript
              </Label>
              <div className="col-sm-9">
                <InputGroup>
                    <Input value={file2.fileFormValue}
                           className={`form-control${
                             file2.error ? ' is-invalid' : ''
                           }`}
                    />
                    <Button onClick={handleClick2} className="btn btn-success ">
                      Upload a file
                    </Button>
                    {file2.error &&
                      <div className="invalid-feedback">File size too big</div>}
                    <input
                      style={{display: 'none'}}
                      accept="image/png, image/jpeg, application/pdf, image/HEIC"
                      ref={hiddenFileInput2}
                      id="file2"
                      type="file"
                      onChange={e => {handleFileChange1(copyState, setFile2)(e)}}
                    />
                </InputGroup>
              </div>
              {file2.loading && <Spinner className="me-2" type="grow" color="primary" />}
            </div>

            <div className="mb-3 row">
              <Label htmlFor="disciplineCertificate" className="col-sm-2 col-form-label" >
                IEP
              </Label>
              <div className="col-sm-9">
                <InputGroup>
                  <Input value={file3.fileFormValue}
                         className={`form-control${
                           file3.error ? ' is-invalid' : ''
                         }`}
                  />
                  <Button onClick={handleClick3} className="btn btn-success ">
                    Upload a file
                  </Button>
                  {file3.error &&
                    <div className="invalid-feedback">File size too big</div>}
                  <input
                    style={{display: 'none'}}
                    accept="image/png, image/jpeg, application/pdf"
                    ref={hiddenFileInput3}
                    id="file3"
                    type="file"
                    onChange={e => {handleFileChange1(copyState, setFile3)(e)}}
                  />
                </InputGroup>
              </div>
              {file3.loading && <Spinner className="me-2" type="grow" color="primary" />}
            </div>

            <div className="mb-3 row">
              <Label htmlFor="immunizationCard" className="col-sm-2 col-form-label" >
                Immunization Card
              </Label>
              <div className="col-sm-9">
                <InputGroup>
                  <Input value={file4.fileFormValue}
                         className={`form-control${
                           file4.error ? ' is-invalid' : ''
                         }`}
                  />
                  <Button onClick={handleClick4} className="btn btn-success ">
                    Upload a file
                  </Button>
                  {file4.error &&
                    <div className="invalid-feedback">File size too big</div>}
                  <input
                    style={{display: 'none'}}
                    accept="image/png, image/jpeg, application/pdf, image/HEIC"
                    ref={hiddenFileInput4}
                    id="file4"
                    type="file"
                    onChange={e => {handleFileChange1(copyState, setFile4)(e)}}
                  />
                </InputGroup>
              </div>
              {file4.loading && <Spinner className="me-2" type="grow" color="primary" />}
            </div>

              <div className="mb-3 row">
                <Label htmlFor="healthAppraisal" className="col-sm-2 col-form-label" >
                  Health Appraisal
                </Label>
                <div className="col-sm-9">
                  <InputGroup>
                    <Input value={file5.fileFormValue}
                           className={`form-control${
                             file5.error ? ' is-invalid' : ''
                           }`}
                    />
                    <Button onClick={handleClick5} className="btn btn-success ">
                      Upload a file
                    </Button>
                    {file5.error &&
                      <div className="invalid-feedback">File size too big</div>}
                    <input
                      style={{display: 'none'}}
                      accept="image/png, image/jpeg, application/pdf, image/HEIC"
                      ref={hiddenFileInput5}
                      id="file5"
                      type="file"
                      onChange={e => {handleFileChange1(copyState, setFile5)(e)}}
                    />
                  </InputGroup>
                </div>
                {file5.loading && <Spinner className="me-2" type="grow" color="primary" />}
              </div>
            </div>


            <CardBody className="border-top gap-2 d-flex align-items-center">
              <FormGroup>
                <Button type="button" className="btn btn-geePrimary ms-2" onClick={prevStep}
                        disabled={file5.loading || file4.loading || file3.loading || file2.loading || file1.loading}>
                  Back
                </Button>
                <Button type="button" onClick={submitForm} className="btn btn-success ms-2"
                        disabled={file5.loading || file4.loading || file3.loading || file2.loading || file1.loading}>
                  Next
                </Button>
              </FormGroup>
            </CardBody>
          </Col>
        </Row>


      </Container>
    </div>
  );
};

Step6.propTypes = {
  nextStep: PropTypes.any,
  values:PropTypes.any,
  copyState:PropTypes.any,
  prevStep: PropTypes.any
};
export default Step6;

