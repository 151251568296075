import React from 'react';
import {Button, Container, Row, Col, CardBody, CardTitle, FormGroup, Label} from 'reactstrap';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import PropTypes from "prop-types";
import {validationSchemaStep3} from "../helpers/FormValidation"
import {languages} from "../helpers/Initialization";


const Step3 = ({ nextStep, formValues, copyState, prevStep }) => {
  return (
    <div >
      <Container fluid >
        <Row className="justify-content-center align-items-center">

                <Formik
                  initialValues={formValues}
                  validationSchema={validationSchemaStep3}

                  onSubmit={(fields) => {

                    console.log(fields);

                    if (fields.student.languageQ1 === 'Yes')
                      fields.student.languageQ7 = "370"

                    if (fields.student.languageQ2 === 'Yes')
                      fields.student.languageQ8 = "370"

                    if (fields.student.languageQ6 === 'Yes') {
                      fields.student.languageQ3 = null;
                      fields.student.languageQ4 = null;
                      fields.student.languageQ5 = "";
                    }


                    copyState(prevState => ({
                      ...prevState,
                      ...fields
                    }));


                    nextStep();
                  }}

                  render={({ errors, touched, values}) => (
                    <Form>
                      <Col md="12">
                        <CardBody className="bg-light">
                          <CardTitle tag="h4" className="mb-0">
                            Student Primary Language Information
                          </CardTitle>
                        </CardBody>

                        <div className="mb-3 p-3 row">
                          <div className={` form-control${
                            errors.student &&  touched.student && touched?.student?.languageQ1 && errors?.student?.languageQ1? ' is-invalid' : ''
                          }`}>
                            <Label htmlFor="name" className="col-sm-12 col-form-label">
                              Is your child’s native language English?
                            </Label>
                            <FormGroup>
                              <Label>
                                <Field type="radio" name="student.languageQ1" value="Yes"  />
                                Yes
                              </Label>
                              &nbsp;&nbsp;
                              <Label>
                                <Field type="radio" name="student.languageQ1" value="No" />
                                No
                              </Label>

                              <ErrorMessage
                                name="student.languageQ1"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </div>
                        </div>

                        {values.student.languageQ1 === 'No' &&
                          <div className="mb-3 row">
                            <Label htmlFor="relationShip" className="col-sm-2 col-form-label">
                              Child Primary Language
                            </Label>
                            <div className="col-sm-10">
                              <FormGroup>
                                <Field as="select" name="student.languageQ7" placeholder="Select School Year"
                                       className={`form-control${
                                         errors.student && touched.student && touched?.student?.languageQ7 && errors?.student?.languageQ7 ? ' is-invalid' : ''
                                       }`}>

                                  {languages?.map(({key, value}) => (
                                    <option value={key}>{value}</option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="student.languageQ7"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </div>
                          </div>
                        }

                        <div className="mb-3 p-3 row">
                          <div className={` form-control${
                            errors.student &&  touched.student && touched?.student?.languageQ2 && errors?.student?.languageQ2? ' is-invalid' : ''
                          }`}>
                            <Label htmlFor="name" className="col-sm-12 col-form-label">
                              Is English the primary language used in your child’s home or environment?
                            </Label>
                            <FormGroup>
                              <Label>
                                <Field type="radio" name="student.languageQ2" value="Yes" />
                                Yes
                              </Label>
                              &nbsp;&nbsp;
                              <Label>
                                <Field type="radio" name="student.languageQ2" value="No" />
                                No
                              </Label>

                              <ErrorMessage
                                name="student.languageQ2"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </div>
                        </div>

                        {values.student.languageQ2 === 'No' &&
                          <div className="mb-3 row">
                            <Label htmlFor="relationShip" className="col-sm-2 col-form-label">
                              Child Home Language
                            </Label>
                            <div className="col-sm-10">
                              <FormGroup>
                                <Field as="select" name="student.languageQ8" placeholder="Select School Year"
                                       className={`form-control${
                                         errors.student && touched.student && touched?.student?.languageQ8 && errors?.student?.languageQ8 ? ' is-invalid' : ''
                                       }`}>
                                  {languages?.map(({key, value}) => (
                                    <option value={key}>{value}</option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="student.languageQ8"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </div>
                          </div>
                        }

                        <div className="mb-3 p-3 row">
                          <div className={` form-control${
                            errors.student &&  touched.student && touched?.student?.languageQ6 && errors?.student?.languageQ6? ' is-invalid' : ''
                          }`}>
                            <Label htmlFor="name" className="col-sm-12 col-form-label">
                              Was your child born in the United States?
                            </Label>
                            <FormGroup>
                              <Label>
                                <Field type="radio" name="student.languageQ6" value="Yes"  />
                                Yes
                              </Label>
                              &nbsp;&nbsp;
                              <Label>
                                <Field type="radio" name="student.languageQ6" value="No" />
                                No
                              </Label>

                              <ErrorMessage
                                name="student.languageQ6"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </div>
                        </div>

                        {values.student.languageQ6 === 'No' &&
                          <div className="mb-3 p-3 row">
                            <div className="form-control">

                              <div className="mb-3 row">
                                <Label htmlFor="birthday" className="col-sm-12 col-form-label">
                                  What date did the student enter the United States?
                                </Label>

                                <div className="col-sm-12">
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <FormGroup>
                                        <Field
                                          placeholder="Birthday"
                                          name="student.languageQ3"
                                          type="date"
                                          className={`form-control${
                                            errors.student && touched.student && touched?.student?.languageQ3 && errors?.student?.languageQ3 ? ' is-invalid' : ''
                                          }`}
                                          //onChange={e => handleChange(index, e)}
                                        />
                                        <ErrorMessage
                                          name="student.languageQ3"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="mb-3 row">
                                <Label htmlFor="birthday" className="col-sm-12 col-form-label">
                                  How many years has the student attended school in the United States?
                                </Label>
                                <div className="col-sm-12">
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <FormGroup>
                                        <Field
                                          placeholder="Years"
                                          name="student.languageQ4"
                                          type="text"
                                          className={`form-control${
                                            errors.student && touched.student && touched?.student?.languageQ4 && errors?.student?.languageQ4 ? ' is-invalid' : ''
                                          }`}
                                          //onChange={e => handleChange(index, e)}
                                        />
                                        <ErrorMessage
                                          name="student.languageQ4"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="mb-3 row">
                                <Label htmlFor="birthday" className="col-sm-12 col-form-label">
                                  What is the student’s country of origin?
                                </Label>
                                <div className="col-sm-12">
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <FormGroup>
                                        <Field
                                          placeholder="Country"
                                          name="student.languageQ5"
                                          type="text"
                                          className={`form-control${
                                            errors.student && touched.student && touched?.student?.languageQ5 && errors?.student?.languageQ5 ? ' is-invalid' : ''
                                          }`}
                                          //onChange={e => handleChange(index, e)}
                                        />
                                        <ErrorMessage
                                          name="student.languageQ5"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }

                        <CardBody className="border-top gap-2 d-flex align-items-center">
                          <FormGroup>
                            <Button type="button" className="btn btn-geePrimary ms-2" onClick={prevStep}>
                              Back
                            </Button>
                            <Button type="submit" className="btn btn-success ms-2">
                              Next
                            </Button>
                          </FormGroup>
                        </CardBody>
                      </Col>
                    </Form>
                  )}
                />
        </Row>
      </Container>
    </div>
  );
};

Step3.propTypes = {
  nextStep: PropTypes.any,
  formValues:PropTypes.any,
  copyState:PropTypes.any,
  prevStep: PropTypes.any
};
export default Step3;

