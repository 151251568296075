import axios from "axios";
import {newStudentApplication, newStudentApplicationState} from "./Initialization";
import AppConfig from "../../../config";

export const handleFileChange = (setStudentFormValues) => async (i, e) => {

  const size =
    (e.target.files[0].size / 1024 / 1024).toFixed(2);

  if (size > 4 ) {
    setStudentFormValues((prevState) => {
        const newState = [ ...prevState]
        newState[i][`${e.target.id}Error`] = true
        return newState;
      }
    );
  } else {

    if (e.target.files.length === 0)
      return

    setStudentFormValues((prevState) => {
        const newState = [...prevState]
        newState[i][`${e.target.id}Loading`] = true
        newState[i][`${e.target.id}Error`] = false
        return newState;
      }
    );

    const fileData = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };

    const formData = new FormData();
    formData.append("file", fileData.data);

    try {
      const response = await axios.post(AppConfig.uploadFileURI, formData);
      const responseWithBody = response.data.response.data;

      setStudentFormValues((prevState) => {
          const newState = [...prevState]
          newState[i][e.target.id] = responseWithBody.webViewLink
          newState[i][`${e.target.id}Loading`] = false
          return newState;
        }
      );
    } catch (error) {
      //todo
      console.log(error.message);
    }

    setStudentFormValues((prevState) => {
        const newState = [...prevState]
        newState[i][`${e.target.id}Loading`] = false
        return newState;
      }
    );
  }
}


export const removeFormFields = (setStudentFormValues, initialValues) => (i) => {

  setStudentFormValues((prevState) => {
      const newState = [ ...prevState]
      newState.splice(i, 1);
      return newState;
    }
  );

  initialValues.student.splice(i,1);
}

export const addFormFields = (setStudentFormValues, initialValues) => () => {

  setStudentFormValues((prevState) => {
      const newState = [
        ...prevState,
        newStudentApplicationState,
      ]

      return newState;
    }
  );

  initialValues.student = [
    ...initialValues.student,
    newStudentApplication
  ]
}


const updateFileAndInitialValue = ({loading, error, fileUrl, fileName, target, fileSetter, copyState}) => {
  const file = {
    file: fileUrl || '',
    error: error || false,
    loading: loading || false,
    fileFormValue: fileName || ''
  }
  fileSetter(file);

  copyState((prevState) => {
      const newState = prevState
      newState.student[target] = file
      return newState;
    }
  );


}

export const handleFileChange1 = (copyState, fileSetter) => async (e) => {

  if (e.target.files.length === 0) {
    fileSetter(prevState => ({
      ...prevState,
      error: false
    }));
  } else {
    const size = (e.target.files[0].size / 1024 / 1024).toFixed(2);

    if (size > 15 ) {

      updateFileAndInitialValue(
        {
          fileSetter,
          copyState,
          target: e.target.id,
          loading: false,
          error: true
        })

    } else {

      updateFileAndInitialValue(
        {
          fileSetter,
          copyState,
          target: e.target.id,
          loading: true,
          error: false
        })

      const fileData = {
        preview: URL.createObjectURL(e.target.files[0]),
        data: e.target.files[0],
      };

      const formData = new FormData();
      formData.append("file", fileData.data);

      try {
        const response = await axios.post(AppConfig.uploadFileURI, formData);
        const responseWithBody = response.data.response.data;
        updateFileAndInitialValue(
          {
            fileSetter,
            copyState,
            target: e.target.id,
            loading: false,
            error: false,
            fileUrl: responseWithBody.webViewLink,
            fileName: e.target.files[0].name
          })
      } catch (error) {
        //todo
        console.log(error.message);
        updateFileAndInitialValue(
          {
            fileSetter,
            copyState,
            target: e.target.id,
            loading: false,
            error: true
          })
      }
    }
  }

}

