import {CardBody, FormGroup,  Label} from "reactstrap";
import {ErrorMessage, Field} from "formik";
import PropTypes from "prop-types";

const HomeDistrictFormFields = ({touched, errors}) => {
  return (
    <CardBody>

      <div className="mb-3 p-3 row">
        <div className={` form-control${
          errors.homeDistrictQ1 &&  touched.homeDistrictQ1 ? ' is-invalid' : ''
        }`}>
          <Label htmlFor="name" className="col-sm-12 col-form-label">
            Do you feel your home district (local public school) provided the program necessary to meet your child’s academic and social needs, and in an environment that you felt was safe?
          </Label>
          <FormGroup>
            <Label>
              <Field type="radio" name="homeDistrictQ1" value="Yes"  />
              Yes
            </Label>
            &nbsp;&nbsp;
            <Label>
              <Field type="radio" name="homeDistrictQ1" value="No" />
              No
            </Label>
            &nbsp;&nbsp;
            <Label>
              <Field type="radio" name="homeDistrictQ1" value="NA"  />
              Not Applicable
            </Label>

            <ErrorMessage
              name="homeDistrictQ1"
              component="div"
              className="invalid-feedback"
            />
          </FormGroup>
        </div>
      </div>

      <div className="mb-3 p-3 row">
        <div className={` form-control${
          errors.homeDistrictQ1 &&  touched.homeDistrictQ1 ? ' is-invalid' : ''
        }`}>
          <Label htmlFor="name" className="col-sm-12 col-form-label">
            Why did you choose to leave your home district (local public school)?
          </Label>
          <FormGroup>
            <Field
              placeholder=""
              name="homeDistrictQ2"
              type="text"
              className={`form-control${
                errors.homeDistrictQ2 &&  touched.homeDistrictQ2  ? ' is-invalid' : ''
              }`}
              //onChange={e => handleChange(index, e)}
            />

            <ErrorMessage
              name="homeDistrictQ1"
              component="div"
              className="invalid-feedback"
            />
          </FormGroup>
        </div>
      </div>

    </CardBody>
)
}

HomeDistrictFormFields.propTypes = {
  touched: PropTypes.any,
  errors:PropTypes.any,
};

export default HomeDistrictFormFields;