import React from 'react';
import {Button, Container, Row, Col, CardBody, CardTitle, FormGroup} from 'reactstrap';
import {Formik, Form} from 'formik';
import PropTypes from "prop-types";
import {validationSchemaStep1} from "../helpers/FormValidation"
import ParentFormFields from "../helpers/ParentFormFields";
import HomeDistrictFormFields from "../helpers/HomeDistrictFormFields";



const Step1 = ({ nextStep, values, copyState }) => {
console.log(values);
  return (
    <div >
      <Container fluid >
        <Row className="justify-content-center align-items-center">

                <Formik
                  initialValues={values}
                  validationSchema={validationSchemaStep1}

                  onSubmit={(fields) => {

                    console.log(fields);

                    copyState(prevState => ({
                      ...prevState,
                      ...fields
                    }));


                    nextStep();
                  }}

                  render={({ errors, touched}) => (
                    <Form>
                      <Col md="12">
                          <CardBody className="bg-light">
                            <CardTitle tag="h4" className="mb-0">
                              Parent/Guardian Information
                            </CardTitle>
                          </CardBody>
                          <ParentFormFields {...({touched, errors})} />

                          <CardBody className="bg-light">
                            <CardTitle tag="h4" className="mb-0">
                              Home District Information
                            </CardTitle>
                          </CardBody>
                          <HomeDistrictFormFields {...({touched, errors})} />

                        <CardBody className="border-top gap-2 d-flex align-items-center">
                          <FormGroup>
                            <Button type="submit" className="btn btn-success ms-2">
                              Next
                            </Button>
                          </FormGroup>
                        </CardBody>
                      </Col>
                    </Form>
                  )}
                />

        </Row>
      </Container>
    </div>
  );
};

Step1.propTypes = {
  nextStep: PropTypes.any,
  values:PropTypes.any,
  copyState:PropTypes.any
};

export default Step1;

