import React from 'react';
import {Container, Row, Col, Card, CardBody, Button, FormGroup} from 'reactstrap';
import {useMutation} from "@apollo/client";
import {useNavigate, useParams} from 'react-router-dom';
import LoginLogo from "../../layouts/logo/LoginFormLogo";
import {LOGIN_MUTATION} from "../../graphql/registeration";
import {setToken} from "../../helper/authHelper";


const RegistrationComplete = () => {

  const navigate = useNavigate();
  const { registrationId } = useParams();
  const { email } = useParams();

  const backToLogin = () =>{
    const path = `/?registrationId=${registrationId}&email=${email}`;
    navigate(path);
  }

  const [checkRegistration, {data}] = useMutation(LOGIN_MUTATION);


  if (data) {
    if (data.login) {
      setToken({token: data.login.id});
      navigate('/');
    }
  }

  if (registrationId === undefined) navigate('/');

  return (
      <div className="loginBox">
        <Container fluid className="h-100">
          <Row className="justify-content-center align-items-center h-100">
            <Col lg="12" className="loginContainer">
              <Card>
                <CardBody className="p-4 m-1">
                  <LoginLogo/>
                  <h5 className="mb-0">Thank you for registration, please use your email and the registration ID below
                    to check on the status of your application</h5>
                  <br/>
                  <h3 className="pb-4 d-block text-center">
                    Registration ID  {registrationId}
                  </h3>


                  <FormGroup>
                    <Button color="geePrimary" className="align-self-center" block onClick={backToLogin}>
                      Check Registration Status
                    </Button>
                  </FormGroup>
                  <FormGroup>
                    <Button color="success" className="align-self-center" block onClick={()=>
                      checkRegistration({ variables: {
                          getRegistrationByIdAndEmailId: registrationId,
                          email: email.toLowerCase()

                        }
                    })}>
                      Add Another Student
                    </Button>
                  </FormGroup>
                  {/*<small className="pb-4 d-block align-items-md-center justify-content-center"  >*/}
                  {/*  Ready to check your application status?*/}
                  {/*  <h6> <Link to={`/?registrationId=${registrationId}&email=${email}`}>Check Registration Status or Add another Student</Link></h6>*/}
                  {/*</small>*/}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )

};

export default RegistrationComplete;
