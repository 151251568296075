import React, {useEffect} from 'react';
import {Spinner} from 'reactstrap';
import {useMutation, useQuery} from "@apollo/client";
import {useNavigate, useParams} from 'react-router-dom';
import moment from "moment";
import {
  GET_APPLICATION_UPDATES_QUERY,
  UPDATE_STUDENT_APPLICATION_QUERY
} from "../../../graphql/registeration";
import {getToken} from "../../../helper/authHelper";

import {formApplicationState} from "../helpers/Initialization";
import StepsContainer from "../helpers/StepsContainer";

const UpdateStudentInfoRegisterForm2 = () => {
  let initialValues;
  let updateInitialValues = false
  const { studentId } = useParams();
  const [token] = React.useState(getToken());

  const applications = useQuery(GET_APPLICATION_UPDATES_QUERY,
    {
      skip: !token,
      variables: { getRegistrationByIdAndEmailId: token },
      fetchPolicy: 'network-only'
    }
  );

  const [studentFormValues, setStudentFormValues] = React.useState(formApplicationState)
  const [updateStudent, {data}] = useMutation(UPDATE_STUDENT_APPLICATION_QUERY);

  if (applications.data) {

    const dbStudentApplication = applications.data.getApplicationUpdates.applications.filter(it => it.id === studentId)
    const studentApplications  = dbStudentApplication.map(it => ({
      id: it.id,
      firstName: it.firstName,
      lastName : it.lastName,
      schoolId: it.schoolId,
      gradeLevel : it.gradeLevel,
      gender: it.gender,
      schoolYear: it.schoolYear,
      birthday: moment(it.birthDate).format('YYYY-MM-DD'),
      ethnicity: it.ethnicity,
      currentSchoolName: it.currentSchoolName,
      currentSchoolAddress: it.currentSchoolAddress,
      currentSchoolPhone: it.currentSchoolPhone,
      currentSchoolFax: it.currentSchoolFax,
      currentSchoolDistrict: it.currentSchoolDistrict,
      languageQ1: it.languageQ1,
      languageQ2: it.languageQ2,
      languageQ3: moment(it.languageQ3).format('YYYY-MM-DD'),
      languageQ4: it.languageQ4,
      languageQ5: it.languageQ5,
      languageQ7: it.languageQ7,
      languageQ8: it.languageQ8,
      languageQ6: it.languageQ3 === null && it.languageQ4 === null && it.languageQ5 === '' ? 'Yes': 'No',
      specialEdQ1: it.specialEdQ1,
      specialEdQ2: it.specialEdQ2,
      specialEdQ3: it.specialEdQ3,
      specialEdQ4: it.specialEdQ4,
      specialEdQ5: it.specialEdQ4,
      specialEdQ6: it.specialEdQ6,
      specialEdQ7: it.specialEdQ7,
      housing1: it.housing1,
      housing2: it.housing2,
      housing3: it.housing3,
      housing4: it.housing4,
      housing5: it.housing5,
      housing6: it.housing6,
      housing7: it.housing7,
      housing8: it.housing8,
      housing9: it.housing9,
      file1:{file:"", error: false, loading:false, fileFormValue:""},
      file2:{file:"", error: false, loading:false, fileFormValue:""},
      file3:{file:"", error: false, loading:false, fileFormValue:""},
      file4:{file:"", error: false, loading:false, fileFormValue:""},
      file5:{file:"", error: false, loading:false, fileFormValue:""},
      notes: null,
      hasSibling:  it.hasSibling,
      siblingInfo: (it.siblingInfo == null || !it.siblingInfo) ? "": it.siblingInfo
    }))

    initialValues = {
      applicationId: applications.data.getApplicationUpdates.id,
      parentLastName: applications.data.getApplicationUpdates.lastName,
      parentFirstName: applications.data.getApplicationUpdates.firstName,
      relation: applications.data.getApplicationUpdates.relationship,
      email: applications.data.getApplicationUpdates.email,
      pPhone: applications.data.getApplicationUpdates.primaryContactPhone,
      emergencyFirstName: applications.data.getApplicationUpdates.emergencyFirstName,
      emergencyLastName: applications.data.getApplicationUpdates.emergencyLastName,
      emergencyRelationship: applications.data.getApplicationUpdates.emergencyRelationship,
      secondaryPhone: applications.data.getApplicationUpdates.secondaryPhone,
      phoneType: applications.data.getApplicationUpdates.phoneType,
      secondaryPhoneType: applications.data.getApplicationUpdates.secondaryPhoneType,
      address: applications.data.getApplicationUpdates.address,
      city: applications.data.getApplicationUpdates.city,
      state: applications.data.getApplicationUpdates.state,
      postalCode: applications.data.getApplicationUpdates.postalCode,
      freeAndReduced: applications.data.getApplicationUpdates.freeAndReduced,
      homeDistrictQ1: applications.data.getApplicationUpdates.homeDistrictQ1,
      homeDistrictQ2: applications.data.getApplicationUpdates.homeDistrictQ2,
      signature: "",
      student: studentApplications[0]
    };
    updateInitialValues = true

  }

  const updateApplication = () => {
    const studentApplications = ({
      id: studentFormValues.student.id,
      firstName: studentFormValues.student.firstName,
      lastName : studentFormValues.student.lastName,
      schoolId: studentFormValues.student.schoolId,
      gradeLevel: studentFormValues.student.gradeLevel,
      birthDate: studentFormValues.student.birthday,
      gender: studentFormValues.student.gender,
      ethnicity: studentFormValues.student.ethnicity,
      schoolYear: studentFormValues.student.schoolYear,
      currentSchoolName: studentFormValues.student.currentSchoolName,
      currentSchoolAddress: studentFormValues.student.currentSchoolAddress,
      currentSchoolPhone: studentFormValues.student.currentSchoolPhone,
      currentSchoolFax: studentFormValues.student.currentSchoolFax,
      currentSchoolDistrict: studentFormValues.student.currentSchoolDistrict,
      //primaryLanguage: student.primaryLanguage,
      languageQ1: studentFormValues.student.languageQ1,
      languageQ2: studentFormValues.student.languageQ2,
      languageQ3: studentFormValues.student.languageQ3 === "" ? null : studentFormValues.student.languageQ3,
      languageQ4: studentFormValues.student.languageQ4 === "" ? null : studentFormValues.student.languageQ4,
      languageQ5: studentFormValues.student.languageQ5,
      languageQ7: studentFormValues.student.languageQ7,
      languageQ8: studentFormValues.student.languageQ8,
      specialEdQ1: studentFormValues.student.specialEdQ1,
      specialEdQ2: studentFormValues.student.specialEdQ2,
      specialEdQ3: studentFormValues.student.specialEdQ3,
      specialEdQ4: studentFormValues.student.specialEdQ4,
      specialEdQ5: studentFormValues.student.specialEdQ4,
      specialEdQ6: studentFormValues.student.specialEdQ6,
      specialEdQ7: studentFormValues.student.specialEdQ7,
      housing1: studentFormValues.student.housing1 || false,
      housing2: studentFormValues.student.housing2 || false,
      housing3: studentFormValues.student.housing3 || false,
      housing4: studentFormValues.student.housing4 || false,
      housing5: studentFormValues.student.housing5 || false,
      housing6: studentFormValues.student.housing6 || false,
      housing7: studentFormValues.student.housing7 || false,
      housing8: studentFormValues.student.housing8 || false,
      housing9: studentFormValues.student.housing9 || false,
      notes: studentFormValues.student.notes,
      birthCertificateFilePath: studentFormValues.student.file1.file,
      grades1FilePath: studentFormValues.student.file2.file,
      grades2FilePath: studentFormValues.student.file3.file,
      grades3FilePath: studentFormValues.student.file4.file,
      healthAppraisalFilePath: studentFormValues.student.file5.file,
      hasSibling: studentFormValues.student.hasSibling,
      siblingInfo: (studentFormValues.student.hasSibling === 'No'
        || studentFormValues.student.siblingInfo === "" )? null : studentFormValues.student.siblingInfo.trim(),
      })
    const input = {
      id: studentFormValues.applicationId,
      email: studentFormValues.email.toLowerCase(),
      firstName: studentFormValues.parentFirstName,
      lastName: studentFormValues.parentLastName,
      primaryContactPhone: studentFormValues.pPhone,
      secondaryPhone: studentFormValues.secondaryPhone,
      phoneType: studentFormValues.phoneType,
      emergencyFirstName: studentFormValues.emergencyFirstName,
      emergencyLastName: studentFormValues.emergencyLastName,
      emergencyRelationship: studentFormValues.emergencyRelationship,
      secondaryPhoneType: studentFormValues.secondaryPhoneType,
      address: studentFormValues.address,
      city: studentFormValues.city,
      state: studentFormValues.state,
      postalCode: studentFormValues.postalCode,
      relationship: studentFormValues.relation,
      freeAndReduced: studentFormValues.freeAndReduced,
      homeDistrictQ1: studentFormValues.homeDistrictQ1,
      homeDistrictQ2: studentFormValues.homeDistrictQ2,
      signature: studentFormValues.signature,
      //applications: [...studentFormValues]
      applications: [studentApplications]
    }
    updateStudent({
      variables: { input }
    })
    //console.log(applicationId);
  }

  useEffect(() => {
    if (initialValues) {
      setStudentFormValues(initialValues);
      console.log(initialValues);
    }
  }, [updateInitialValues]);

  const navigate = useNavigate();

  if (data) {
    navigate("/");
  }
  if (applications.data && studentFormValues.applicationId !== "" ) {
    return (
      <StepsContainer done={updateApplication} studentFormValues={studentFormValues} setStudentFormValues={setStudentFormValues} />
    );
  }
  return <Spinner color="primary" />
};

export default UpdateStudentInfoRegisterForm2;
