import React from 'react';
import {Card, CardBody, CardTitle, Col, Container, Progress, Row} from "reactstrap";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import LoginLogo from "../../../layouts/logo/LoginFormLogo";
import Step1 from "../steps/Steps1";
import Step2 from "../steps/Steps2";
import Step3 from "../steps/Steps3";
import Step4 from "../steps/Steps4";
import Step5 from "../steps/Steps5";
import Step6 from "../steps/Steps6";
import Step7 from "../steps/Steps7";

const StepsContainer = ({done, studentFormValues, setStudentFormValues}) => {
  const [step, setStep] = React.useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setStep(step - 1);
  };

  return (
    <div >
      <Container fluid >
        <Row className="justify-content-center align-items-center">
          <Col lg="12" className="loginContainer">
            <Card>
              <LoginLogo />
              <CardBody className="p-4 m-1">
                <small className="pb-4 d-block">
                  Already have an account? <Link to="/">Check Registration Status</Link>
                </small>
                <Col md="12">
                  <Card>
                    <CardTitle tag="h4" className="border-bottom bg-geePrimary p-3 mb-0 text-white">
                      Registration form
                    </CardTitle>

                        <Progress className="m-3" striped color="success" style={{ height: '1.0rem' }} value={step * 100 / 7}  />


                    { step === 1 &&
                      <Step1 nextStep={nextStep} copyState={setStudentFormValues} values={studentFormValues} />}
                    { step === 2 &&
                      <Step2 nextStep={nextStep} prevStep={prevStep} copyState={setStudentFormValues} formValues={studentFormValues} />}
                    { step === 3 &&
                      <Step3 nextStep={nextStep} prevStep={prevStep} copyState={setStudentFormValues} formValues={studentFormValues} />}
                    { step === 4 &&
                      <Step4 nextStep={nextStep} prevStep={prevStep} copyState={setStudentFormValues} values={studentFormValues} />}
                    { step === 5 &&
                      <Step5 nextStep={nextStep} prevStep={prevStep} copyState={setStudentFormValues} values={studentFormValues} />}
                    { step === 6 &&
                      <Step6 nextStep={nextStep} prevStep={prevStep} copyState={setStudentFormValues} values={studentFormValues}/>}
                    { step === 7 &&
                      <Step7 nextStep={done} prevStep={prevStep} copyState={setStudentFormValues} values={studentFormValues}/>}

                  </Card>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

StepsContainer.propTypes = {
  studentFormValues: PropTypes.any,
  setStudentFormValues:PropTypes.any,
  done:PropTypes.any
};

export default StepsContainer;
