import React from 'react';
import {Button, Container, Row, Col, CardBody, CardTitle, FormGroup, Label} from 'reactstrap';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import PropTypes from "prop-types";
import {validationSchemaStep5} from "../helpers/FormValidation"

const Step5 = ({ nextStep, values, copyState, prevStep }) => {
  return (
    <div >
      <Container fluid >
        <Row className="justify-content-center align-items-center">
          
                <Formik
                  initialValues={values}
                  validationSchema={validationSchemaStep5}

                  onSubmit={(fields) => {

                    console.log(fields);

                    copyState(prevState => ({
                      ...prevState,
                      ...fields
                    }));


                    nextStep();
                  }}

                  render={({ errors, touched}) => (
                    <Form>
                      <Col md="12">
                        <CardBody className="bg-light">
                          <CardTitle tag="h4" className="mb-0">
                            Student Special Education Information
                          </CardTitle>
                        </CardBody>

                        <div className="mb-3 p-3 row">
                          <div className={` form-control${
                            errors.student &&  touched.student && touched?.student?.specialEdQ1 && errors?.student?.specialEdQ1? ' is-invalid' : ''
                          }`}>
                            <Label htmlFor="name" className="col-sm-12 col-form-label">
                              Have you ever attended an I.E.P.C. (Individualized Educational Planning Committee) meeting where your child’s eligibility for Special Education was discussed?
                            </Label>
                            <FormGroup>
                              <Label>
                                <Field type="radio" name="student.specialEdQ1" value="Yes"  />
                                Yes
                              </Label>
                              &nbsp;&nbsp;
                              <Label>
                                <Field type="radio" name="student.specialEdQ1" value="No" />
                                No
                              </Label>

                              <ErrorMessage
                                name="student.specialEdQ1"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </div>
                        </div>

                        <div className="mb-3 p-3 row">
                          <div className={` form-control${
                            errors.student &&  touched.student && touched?.student?.specialEdQ2 && errors?.student?.specialEdQ2? ' is-invalid' : ''
                          }`}>
                            <Label htmlFor="name" className="col-sm-12 col-form-label">
                              Is your child currently enrolled in Special Education?
                            </Label>
                            <FormGroup>
                              <Label>
                                <Field type="radio" name="student.specialEdQ2" value="Yes"  />
                                Yes
                              </Label>
                              &nbsp;&nbsp;
                              <Label>
                                <Field type="radio" name="student.specialEdQ2" value="No" />
                                No
                              </Label>

                              <ErrorMessage
                                name="student.specialEdQ2"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </div>
                        </div>
                        <div className="mb-3 p-3 row">
                          <div className={` form-control${
                            errors.student &&  touched.student && touched?.student?.specialEdQ7 && errors?.student?.specialEdQ7? ' is-invalid' : ''
                          }`}>
                            <Label htmlFor="name" className="col-sm-12 col-form-label">
                              Has your child received special education services in the past?
                            </Label>
                            <FormGroup>
                              <Label>
                                <Field type="radio" name="student.specialEdQ7" value="Yes"  />
                                Yes
                              </Label>
                              &nbsp;&nbsp;
                              <Label>
                                <Field type="radio" name="student.specialEdQ7" value="No" />
                                No
                              </Label>

                              <ErrorMessage
                                name="student.specialEdQ7"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </div>
                        </div>
                        <div className="mb-3 p-3 row">
                          <div className={` form-control${
                            errors.student &&  touched.student && touched?.student?.specialEdQ3 && errors?.student?.specialEdQ3? ' is-invalid' : ''
                          }`}>
                            <Label htmlFor="name" className="col-sm-12 col-form-label">
                              My child does not receive special services, but they do have a 504 plan
                            </Label>
                            <FormGroup>
                              <Label>
                                <Field type="radio" name="student.specialEdQ3" value="Yes"  />
                                Yes
                              </Label>
                              &nbsp;&nbsp;
                              <Label>
                                <Field type="radio" name="student.specialEdQ3" value="No" />
                                No
                              </Label>

                              <ErrorMessage
                                name="student.specialEdQ3"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </div>
                        </div>

                        <div className="mb-3 p-3 row">
                          <div className={` form-control${
                            errors.student &&  touched.student && touched?.student?.specialEdQ4 && errors?.student?.specialEdQ4? ' is-invalid' : ''
                          }`}>
                            <Label htmlFor="name" className="col-sm-12 col-form-label">
                              Did your child receive any other special services, such as social work referrals to other sources, counseling, tutoring, etc.?
                            </Label>
                            <FormGroup>
                              <Label>
                                <Field type="radio" name="student.specialEdQ4" value="Yes"  />
                                Yes
                              </Label>
                              &nbsp;&nbsp;
                              <Label>
                                <Field type="radio" name="student.specialEdQ4" value="No" />
                                No
                              </Label>

                              <ErrorMessage
                                name="student.specialEdQ4"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </div>
                        </div>

                        <div className="mb-3 p-3 row">
                          <div className={` form-control${
                            errors.student &&  touched.student && touched?.student?.specialEdQ5 && errors?.student?.specialEdQ5? ' is-invalid' : ''
                          }`}>
                            <Label htmlFor="name" className="col-sm-12 col-form-label">
                              If your child has been a part of a Special Education program, do you have a copy of your child’s current I.E.P. (Individualized Education Plan)?
                            </Label>
                            <FormGroup>
                              <Label>
                                <Field type="radio" name="student.specialEdQ5" value="Yes"  />
                                Yes
                              </Label>
                              &nbsp;&nbsp;
                              <Label>
                                <Field type="radio" name="student.specialEdQ5" value="No" />
                                No
                              </Label>

                              <ErrorMessage
                                name="student.specialEdQ5"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </div>
                        </div>

                        <div className="mb-3 p-3 row">
                          <div className={` form-control${
                            errors.student &&  touched.student && touched?.student?.specialEdQ6 && errors?.student?.specialEdQ6? ' is-invalid' : ''
                          }`}>
                            <Label htmlFor="name" className="col-sm-12 col-form-label">
                              Do you feel your child is a candidate for Special Services?
                            </Label>
                            <FormGroup>
                              <Label>
                                <Field type="radio" name="student.specialEdQ6" value="Yes"  />
                                Yes
                              </Label>
                              &nbsp;&nbsp;
                              <Label>
                                <Field type="radio" name="student.specialEdQ6" value="No" />
                                No
                              </Label>

                              <ErrorMessage
                                name="student.specialEdQ6"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </div>
                        </div>

                        <CardBody className="border-top gap-2 d-flex align-items-center">
                          <FormGroup>
                            <Button type="button" className="btn btn-geePrimary ms-2" onClick={prevStep}>
                              Back
                            </Button>
                            <Button type="submit" className="btn btn-success ms-2">
                              Next
                            </Button>
                          </FormGroup>
                        </CardBody>
                      </Col>
                    </Form>
                  )}
                />
        </Row>
      </Container>
    </div>
  );
};

Step5.propTypes = {
  nextStep: PropTypes.any,
  values:PropTypes.any,
  copyState:PropTypes.any,
  prevStep: PropTypes.any
};

export default Step5;

