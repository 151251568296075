import { lazy } from 'react';
import Loadable from '../layouts/loader/Loadable';
import RegistrationComplete from "../views/auth/RegistrationComplete";

import RegisterForm1 from "../views/auth/steps/Registration";
import UpdateStudentInfoRegisterForm2 from "../views/auth/steps/UpdateRegistration";
import ADDStudentInfoRegisterForm2 from "../views/auth/steps/AddRegistration";

/****Layouts*****/


const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
/***** Pages ****/

const RegistrationDashboard = Loadable(lazy(() => import('../views/dashboards/RegistrationDashboard')));


/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const CheckRegistrationStatusForm = Loadable(lazy(() => import('../views/auth/CheckRegistrationStatusForm')));
const ForgotRegistrationForm = Loadable(lazy(() => import('../views/auth/ForgotPassword')));

/*****Routes******/



/*****Routes******/
export const GEENotLoggedInRoutes = [
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '/', element: <CheckRegistrationStatusForm /> },
      { path: '*', element: <CheckRegistrationStatusForm /> },
      { path: 'registration', element: <RegisterForm1 /> },
      { path: 'forgotRegistration', element: <ForgotRegistrationForm /> },
      { path: 'confirmation/:registrationId/:email', element: <RegistrationComplete /> },
    ]
  }
];

/*****Routes******/
export const GEELoggedInRoutes = [
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '/', element: <RegistrationDashboard /> },
      { path: '*', element: <Error /> },
      { path: 'edit/registration/:studentId', element: <UpdateStudentInfoRegisterForm2 /> },
      { path: 'add/registration', element: <ADDStudentInfoRegisterForm2 /> },

    ]
  }
];


