import React from 'react';
import {Button, Container, Row, Col, CardBody, CardTitle, FormGroup, Label} from 'reactstrap';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import PropTypes from "prop-types";
import {validationSchemaStep2} from "../helpers/FormValidation"

const Step2 = ({ nextStep, formValues, copyState, prevStep }) => {
  return (
    <div >
      <Container fluid >
        <Row className="justify-content-center align-items-center">


                <Formik
                  initialValues={formValues}
                  validationSchema={validationSchemaStep2}

                  onSubmit={(fields) => {
                    console.log(fields);

                    copyState(prevState => ({
                      ...prevState,
                      ...fields
                    }));

                    nextStep();
                  }}

                  render={({ errors, touched, values }) => (
                    <Form>

                      <Col md="12">
                        <CardBody className="bg-light">
                          <CardTitle tag="h4" className="mb-0">
                            Student Information
                          </CardTitle>
                        </CardBody>

                        <CardBody>
                          <div className="mb-3 row">
                            <div className="mb-3 row">
                              <Label htmlFor="name" className="col-sm-2 col-form-label">
                                Student Name
                              </Label>
                              <div className="col-sm-10">
                                <div className="row">
                                  <div className="col-sm-6">
                                    <FormGroup>
                                      <Field
                                        placeholder="First Name"
                                        name="student.firstName"
                                        type="text"
                                        className={`form-control${
                                          errors.student &&  touched.student && touched?.student?.firstName && errors?.student?.firstName ? ' is-invalid' : ''
                                        }`}
                                        //onChange={e => handleChange(index, e)}
                                      />
                                      <ErrorMessage
                                        name="student.firstName"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </div>
                                  <div className="col-sm-6">
                                    <Field
                                      placeholder="Last Name"
                                      name="student.lastName"
                                      type="text"
                                      className={`form-control${
                                        errors.student &&  touched.student && touched?.student?.lastName && errors?.student?.lastName ? ' is-invalid' : ''
                                      }`}
                                      //onChange={e => handleChange(index, e)}
                                    />
                                    <ErrorMessage
                                      name="student.lastName"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="mb-3 row">
                              <Label htmlFor="birthday" className="col-sm-2 col-form-label">
                                Date of Birth
                              </Label>

                              <div className="col-sm-10">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <FormGroup>
                                      <Field
                                        placeholder="Birthday"
                                        name="student.birthday"
                                        type="date"
                                        className={`form-control${
                                          errors.student &&  touched.student && touched?.student?.birthday && errors?.student?.birthday? ' is-invalid' : ''
                                        }`}
                                      />
                                      <ErrorMessage
                                        name="student.birthday"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="mb-3 row">
                              <Label htmlFor="ethnicity" className="col-sm-2 col-form-label" >
                                Ethnicity
                              </Label>
                              <div className="col-sm-10">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <FormGroup>
                                      <Field as="select" isMulti="true" name="student.ethnicity" placeholder="Select Ethnicity" className={`form-control${
                                        errors.student &&  touched.student && touched?.student?.ethnicity && errors?.student?.ethnicity? ' is-invalid' : ''
                                      }`}>
                                        <option value="">Select Ethnicity</option>
                                        <option value="asian">Asian</option>
                                        <option value="white">White</option>
                                        <option value="black">Black</option>
                                        <option value="hispanic">Hispanic</option>
                                        <option value="pacific">Pacific</option>
                                        <option value="indian">Indian</option>
                                      </Field>
                                      <ErrorMessage
                                        name="student.ethnicity"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="mb-3 row">
                              <Label htmlFor="relationShip" className="col-sm-2 col-form-label" >
                                Gender
                              </Label>
                              <div className="col-sm-10">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <FormGroup>
                                        <Field as="select" name="student.gender" placeholder="Select Gender" className={`form-control${
                                          errors.student &&  touched.student && touched?.student?.gender && errors?.student?.gender? ' is-invalid' : ''
                                        }`}>
                                          <option value="">Select Gender</option>
                                          <option value="male">male</option>
                                          <option value="female">female</option>
                                        </Field>
                                        <ErrorMessage
                                          name="student.gender"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="mb-3 row">
                              <Label htmlFor="relationShip" className="col-sm-2 col-form-label" >
                                School Year Applying For
                              </Label>
                              <div className="col-sm-10">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <FormGroup>
                                      <Field as="select" name="student.schoolYear" placeholder="Select School Year" className={`form-control${
                                        errors.student &&  touched.student && touched?.student?.schoolYear && errors?.student?.schoolYear? ' is-invalid' : ''
                                      }`}>
                                        <option value="">Select School Year</option>
                                        <option value="2024">2024-2025 current year</option>
                                        <option value="2025">2025-2026 next year</option>
                                      </Field>
                                      <ErrorMessage
                                        name="student.schoolYear"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="mb-3 row">
                              <Label htmlFor="gradeLevel" className="col-sm-2 col-form-label">
                                Grade Level Applying For
                              </Label>

                              <div className="col-sm-10">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <FormGroup>
                                      <Field as="select" name="student.gradeLevel" placeholder="SelectGrade" className={`form-control${
                                        errors.student &&  touched.student && touched?.student?.gradeLevel && errors?.student?.gradeLevel? ' is-invalid' : ''
                                      }`}>
                                        <option value="">Select Grade</option>
                                        <option value="KG">KG</option>
                                        <option value="1ST">1ST Grade</option>
                                        <option value="2ND">2ND Grade</option>
                                        <option value="3RD">3RD Grade</option>
                                        <option value="4TH">4TH Grade</option>
                                        <option value="5TH">5TH Grade</option>
                                        <option value="6TH">6TH Grade</option>
                                        <option value="7TH">7TH Grade</option>
                                        <option value="8TH">8TH Grade</option>
                                        <option value="9TH">9TH Grade</option>
                                        <option value="10TH">10TH Grade</option>
                                        <option value="11TH">11TH Grade</option>
                                        <option value="12TH">12TH Grade</option>
                                      </Field>
                                      <ErrorMessage
                                        name="student.gradeLevel"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="mb-3 row">
                              <Label htmlFor="relationShip" className="col-sm-2 col-form-label" >
                                School Applying For
                              </Label>
                              <div className="col-sm-10">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <FormGroup>
                                      <Field as="select" name="student.schoolId" placeholder="SelectGrade" className={`form-control${
                                        errors.student &&  touched.student && touched?.student?.schoolId && errors?.student?.schoolId? ' is-invalid' : ''
                                      }`}>
                                        <option value="">Select School</option>
                                        <option value="533">Bridge East</option>
                                        <option value="30000009">Bridge West</option>
                                        <option value="1">Central Academy</option>
                                        <option value="1698">Frontier</option>
                                        <option value="30000028">Frontier Warren</option>
                                        <option value="30000013">Bridge Impact</option>
                                        <option value="30000370">GEE Compass Academy</option>
                                        <option value="30000005">Global Heights</option>
                                        <option value="30000017">Global Tech</option>
                                        <option value="30000079">Pittsfield Acres</option>
                                        <option value="3898">Riverside East</option>
                                        <option value="3899">Riverside West</option>
                                        <option value="30000371">Red Oak Academy</option>
                                      </Field>
                                      <ErrorMessage
                                        name="student.schoolId"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <Label htmlFor="name" className="col-sm-2 col-form-label">
                                Student Current school
                              </Label>

                              <div className="col-sm-10">
                                <div className="row">
                                  <div className="col-sm-6">
                                    <FormGroup>
                                      <Field
                                        placeholder="School Name"
                                        name="student.currentSchoolName"
                                        type="text"
                                        className={`form-control${
                                          errors.student &&  touched.student && touched?.student?.currentSchoolName && errors?.student?.currentSchoolName ? ' is-invalid' : ''
                                        }`}
                                        //onChange={e => handleChange(index, e)}
                                      />
                                      <ErrorMessage
                                        name="student.currentSchoolName"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </div>
                                  <div className="col-sm-6">
                                    <FormGroup>
                                      <Field
                                        placeholder="Address"
                                        name="student.currentSchoolAddress"
                                        type="text"
                                        className={`form-control${
                                          errors.student &&  touched.student && touched?.student?.currentSchoolAddress && errors?.student?.currentSchoolAddress ? ' is-invalid' : ''
                                        }`}
                                      />
                                      <ErrorMessage
                                        name="student.currentSchoolAddress"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-4">
                                    <FormGroup>
                                      <Field
                                        placeholder="Phone"
                                        name="student.currentSchoolPhone"
                                        type="text"
                                        className={`form-control${
                                          errors.student &&  touched.student && touched?.student?.currentSchoolPhone && errors?.student?.currentSchoolPhone ? ' is-invalid' : ''
                                        }`}
                                      />
                                      <ErrorMessage
                                        name="student.currentSchoolPhone"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </div>
                                  <div className="col-sm-4">
                                    <FormGroup>
                                      <Field
                                        placeholder="Fax"
                                        name="student.currentSchoolFax"
                                        type="text"
                                        className={`form-control${
                                          errors.student &&  touched.student && touched?.student?.currentSchoolFax && errors?.student?.currentSchoolFax ? ' is-invalid' : ''
                                        }`}
                                      />
                                      <ErrorMessage
                                        name="student.currentSchoolFax"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </div>
                                  <div className="col-sm-4">
                                    <FormGroup>
                                      <Field
                                        placeholder="District"
                                        name="student.currentSchoolDistrict"
                                        type="text"
                                        className={`form-control${
                                          errors.student &&  touched.student && touched?.student?.currentSchoolDistrict && errors?.student?.currentSchoolDistrict ? ' is-invalid' : ''
                                        }`}
                                      />
                                      <ErrorMessage
                                        name="student.currentSchoolDistrict"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </div>

                          <div className="mb-3 p-3 row">
                            <div className={` form-control${
                              errors.student &&  touched.student && touched?.student?.hasSibling && errors?.student?.hasSibling? ' is-invalid' : ''
                            }`}>
                              <Label htmlFor="name" className="col-sm-12 col-form-label">
                                Does student has siblings in GEE schools?
                              </Label>
                              <FormGroup>
                                <Label>
                                  <Field type="radio" name="student.hasSibling" value="Yes"  />
                                  Yes
                                </Label>
                                &nbsp;&nbsp;
                                <Label>
                                  <Field type="radio" name="student.hasSibling" value="No" />
                                  No
                                </Label>

                                <ErrorMessage
                                  name="student.hasSibling"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </div>
                          </div>
                          {values.student.hasSibling === 'Yes' &&
                            <div className="mb-3 row">
                              <Label htmlFor="relationShip" className="col-sm-2 col-form-label">
                                Please add siblings names, gradeLevel and GEE schools
                              </Label>
                              <div className="col-sm-10">
                                <FormGroup>

                                  <Field
                                    placeholder="SiblingInfo"
                                    name="student.siblingInfo"
                                    type="text"
                                    className={`form-control${
                                      errors.student &&  touched.student && touched?.student?.siblingInfo && errors?.student?.siblingInfo? ' is-invalid' : ''
                                    }`}
                                  />
                                  <ErrorMessage
                                    name="student.siblingInfo"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </div>
                            </div>
                          }
                        </CardBody>
                        <CardBody className="border-top gap-2 d-flex align-items-center">
                        <FormGroup>
                          <Button type="button" className="btn btn-geePrimary ms-2" onClick={prevStep}>
                            Back
                          </Button>
                          <Button type="submit" className="btn btn-success ms-2">
                            Next
                          </Button>
                        </FormGroup>
                      </CardBody>
                      </Col>
                    </Form>
                  )}
                />

        </Row>
      </Container>
    </div>
  );
};

Step2.propTypes = {
  nextStep: PropTypes.any,
  formValues:PropTypes.any,
  copyState:PropTypes.any,
  prevStep: PropTypes.any
};
export default Step2;

