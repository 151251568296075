import {CardBody, FormGroup,  Label} from "reactstrap";
import {ErrorMessage, Field} from "formik";
import PropTypes from "prop-types";

const ParentFormFields = ({touched, errors}) => {
  return (
    <CardBody>
      <div className="mb-3 row">
        <Label htmlFor="name" className="col-sm-2 col-form-label">
          Name
        </Label>
        <div className="col-sm-10">
          <div className="row">
            <div className="col-sm-6">
              <FormGroup>
                <Field
                  placeholder="First Name"
                  name="parentFirstName"
                  type="text"
                  className={`form-control${
                    errors.parentFirstName && touched.parentFirstName ? ' is-invalid' : ''
                  }`}
                />
                <ErrorMessage
                  name="parentFirstName"
                  component="div"
                  className="invalid-feedback"
                />
              </FormGroup>
            </div>
            <div className="col-sm-6">
              <FormGroup>
                <Field
                  placeholder="Last Name"
                  name="parentLastName"
                  type="text"
                  className={`form-control${
                    errors.parentLastName && touched.parentLastName ? ' is-invalid' : ''
                  }`}
                />
                <ErrorMessage
                  name="parentLastName"
                  component="div"
                  className="invalid-feedback"
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-3 row">
        <Label htmlFor="email" className="col-sm-2 col-form-label">
          Email
        </Label>
        <div className="col-sm-10">
          <FormGroup>
            <Field
              placeholder="Email"
              name="email"
              type="text"
              className={`form-control${
                errors.email && touched.email ? ' is-invalid' : ''
              }`}
            />
            <ErrorMessage
              name="email"
              component="div"
              className="invalid-feedback"
            />
          </FormGroup>
        </div>
      </div>

      <div className="mb-3 row">
        <Label htmlFor="relationShip" className="col-sm-2 col-form-label">
          Relationship to student(s)
        </Label>
        <div className="col-sm-10">
          <FormGroup>
            <Field as="select" name="relation" placeholder="Relationship" className={`form-control${
              errors.relation && touched.relation ? ' is-invalid' : ''
            }`}>
              <option value="">Select Relationship</option>
              <option value="mother">Mother</option>
              <option value="father">Father</option>
              <option value="legal-guardian">Legal Guardian</option>
              <option value="other">Others</option>
            </Field>
            <ErrorMessage
              name="relation"
              component="div"
              className="invalid-feedback"
            />
          </FormGroup>

        </div>
      </div>

      <div className="mb-3 row">
        <Label htmlFor="name" className="col-sm-2 col-form-label">
          Phone
        </Label>
        <div className="col-sm-10">
          <div className="row">
            <div className="col-sm-6">
              <FormGroup>
                <Field
                  placeholder="Mobile Phone"
                  name="pPhone"
                  type="text"
                  className={`form-control${
                    errors.pPhone && touched.pPhone ? ' is-invalid' : ''
                  }`}
                />
                <ErrorMessage
                  name="pPhone"
                  component="div"
                  className="invalid-feedback"
                />
              </FormGroup>
            </div>
            <div className="col-sm-6">
              <FormGroup>
                <Field as="select" name="phoneType" placeholder="type" className={`form-control${
                  errors.phoneType && touched.phoneType ? ' is-invalid' : ''
                }`}>
                  <option value="">Select Phone Type</option>
                  <option value="mobile">Mobile</option>
                  <option value="work">Work</option>
                  <option value="home">Home</option>
                </Field>
                <ErrorMessage
                  name="phoneType"
                  component="div"
                  className="invalid-feedback"
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-3 row">
        <Label htmlFor="name" className="col-sm-2 col-form-label">
          Emergency Contact Name
        </Label>
        <div className="col-sm-10">
          <div className="row">
            <div className="col-sm-6">
              <FormGroup>
                <Field
                  placeholder="Emergency Contact First Name"
                  name="emergencyFirstName"
                  type="text"
                  className={`form-control${
                    errors.emergencyFirstName && touched.emergencyFirstName ? ' is-invalid' : ''
                  }`}
                />
                <ErrorMessage
                  name="emergencyFirstName"
                  component="div"
                  className="invalid-feedback"
                />
              </FormGroup>
            </div>
            <div className="col-sm-6">
              <FormGroup>
                <Field
                  placeholder="Emergency Contact Last Name"
                  name="emergencyLastName"
                  type="text"
                  className={`form-control${
                    errors.emergencyLastName && touched.emergencyLastName ? ' is-invalid' : ''
                  }`}
                />
                <ErrorMessage
                  name="emergencyLastName"
                  component="div"
                  className="invalid-feedback"
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-3 row">
        <Label htmlFor="relationShip" className="col-sm-2 col-form-label">
          Emergency Contact Relationship to student(s)
        </Label>
        <div className="col-sm-10">
          <FormGroup>
            <Field as="select" name="emergencyRelationship" placeholder="Emergency Contact Relationship" className={`form-control${
              errors.emergencyRelationship && touched.emergencyRelationship ? ' is-invalid' : ''
            }`}>
              <option value="">Select Relationship</option>
              <option value="mother">Mother</option>
              <option value="father">Father</option>
              <option value="legal-guardian">Legal Guardian</option>
              <option value="other">Others</option>
            </Field>
            <ErrorMessage
              name="emergencyRelationship"
              component="div"
              className="invalid-feedback"
            />
          </FormGroup>

        </div>
      </div>
      <div className="mb-3 row">
        <Label htmlFor="name" className="col-sm-2 col-form-label">
          Emergency Phone
        </Label>
        <div className="col-sm-10">
          <div className="row">
            <div className="col-sm-6">
              <FormGroup>
                <Field
                  placeholder="Mobile Phone"
                  name="secondaryPhone"
                  type="text"
                  className={`form-control${
                    errors.secondaryPhone && touched.secondaryPhone ? ' is-invalid' : ''
                  }`}
                />
                <ErrorMessage
                  name="secondaryPhone"
                  component="div"
                  className="invalid-feedback"
                />
              </FormGroup>
            </div>
            <div className="col-sm-6">
              <FormGroup>
                <Field as="select" name="secondaryPhoneType" placeholder="type" className={`form-control${
                  errors.secondaryPhoneType && touched.secondaryPhoneType ? ' is-invalid' : ''
                }`}>
                  <option value="">Select Phone Type</option>
                  <option value="mobile">Mobile</option>
                  <option value="work">Work</option>
                  <option value="home">Home</option>
                </Field>
                <ErrorMessage
                  name="secondaryPhoneType"
                  component="div"
                  className="invalid-feedback"
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-3 row">
        <Label htmlFor="inputPassword" className="col-sm-2 col-form-label">
          Home Address
        </Label>
        <div className="col-sm-10">
          <div className="row mb-3">
            <div className="col-sm-12">
              <FormGroup>
                <Field
                  placeholder="Address"
                  name="address"
                  type="text"
                  className={`form-control${
                    errors.address && touched.address ? ' is-invalid' : ''
                  }`}
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="invalid-feedback"
                />
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <FormGroup>
                <Field
                  placeholder="City"
                  name="city"
                  type="text"
                  className={`form-control${
                    errors.city && touched.city ? ' is-invalid' : ''
                  }`}
                />
                <ErrorMessage
                  name="city"
                  component="div"
                  className="invalid-feedback"
                />
              </FormGroup>
            </div>
            <div className="col-sm-4">
              <FormGroup>

                <Field as="select" name="state" placeholder="State" className={`form-control${
                  errors.state && touched.state ? ' is-invalid' : ''
                }`}>
                  <option value="">Select State</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="AS">American Samoa</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="GU">Guam</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="MP">Northern Mariana Islands</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="PR">Puerto Rico</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="TT">Trust Territories</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="VI">Virgin Islands</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </Field>
                <ErrorMessage
                  name="state"
                  component="div"
                  className="invalid-feedback"
                />
              </FormGroup>
            </div>
            <div className="col-sm-4">
              <FormGroup>
                <Field
                  placeholder="Postal Code"
                  name="postalCode"
                  type="text"
                  className={`form-control${
                    errors.postalCode && touched.postalCode ? ' is-invalid' : ''
                  }`}
                />
                <ErrorMessage
                  name="postalCode"
                  component="div"
                  className="invalid-feedback"
                />
              </FormGroup>
            </div>
          </div>

        </div>

      </div>

      <div className="mb-3 p-3 row">

        <div className={` form-control${
          errors.freeAndReduced &&  touched.freeAndReduced ? ' is-invalid' : ''
        }`}>
          <Label htmlFor="name" className="col-sm-12 col-form-label">
            Does any member of your household receives Supplemental Nutrition Assistance Program (SNAP) (formerly food stamps) or Ohio Works First (OWF) benefits?
          </Label>
          <FormGroup>
            <Label>
              <Field type="radio" name="freeAndReduced" value="Yes"  />
              Yes
            </Label>
            &nbsp;&nbsp;
            <Label>
              <Field type="radio" name="freeAndReduced" value="No" />
              No
            </Label>

            <ErrorMessage
              name="freeAndReduced"
              component="div"
              className="invalid-feedback"
            />
          </FormGroup>
        </div>

      </div>
    </CardBody>
)
}

ParentFormFields.propTypes = {
  touched: PropTypes.any,
  errors:PropTypes.any,
};

export default ParentFormFields;
