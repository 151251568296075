import React from 'react';
import { useMutation } from "@apollo/client";
import {useNavigate} from 'react-router-dom';
import {REGISTER_QUERY} from "../../../graphql/registeration";
import {formApplicationState} from "../helpers/Initialization";

import StepsContainer from "../helpers/StepsContainer";


const RegisterForm1 = () => {

  const [studentFormValues, setStudentFormValues] = React.useState(formApplicationState)
  const [register, {data}] = useMutation(REGISTER_QUERY);

  const registerApplication = () => {
    console.log(studentFormValues);
    const studentApplications = ({
        firstName: studentFormValues.student.firstName,
        lastName : studentFormValues.student.lastName,
        schoolId: studentFormValues.student.schoolId,
        gradeLevel: studentFormValues.student.gradeLevel,
        birthDate: studentFormValues.student.birthday,
        ethnicity: studentFormValues.student.ethnicity,
        gender: studentFormValues.student.gender,
        schoolYear: studentFormValues.student.schoolYear,
        currentSchoolName: studentFormValues.student.currentSchoolName,
        currentSchoolAddress: studentFormValues.student.currentSchoolAddress,
        currentSchoolPhone: studentFormValues.student.currentSchoolPhone,
        currentSchoolFax: studentFormValues.student.currentSchoolFax,
        currentSchoolDistrict: studentFormValues.student.currentSchoolDistrict,
        //primaryLanguage: student.primaryLanguage,
        languageQ1: studentFormValues.student.languageQ1,
        languageQ2: studentFormValues.student.languageQ2,
        languageQ3: studentFormValues.student.languageQ3 === "" ? null : studentFormValues.student.languageQ3,
        languageQ4: studentFormValues.student.languageQ4 === "" ? null : studentFormValues.student.languageQ4,
        languageQ5: studentFormValues.student.languageQ5,
        languageQ7: studentFormValues.student.languageQ7,
        languageQ8: studentFormValues.student.languageQ8,
        specialEdQ1: studentFormValues.student.specialEdQ1,
        specialEdQ2: studentFormValues.student.specialEdQ2,
        specialEdQ3: studentFormValues.student.specialEdQ3,
        specialEdQ4: studentFormValues.student.specialEdQ4,
        specialEdQ5: studentFormValues.student.specialEdQ4,
        specialEdQ6: studentFormValues.student.specialEdQ6,
        specialEdQ7: studentFormValues.student.specialEdQ7,
        housing1: studentFormValues.student.housing1 || false,
        housing2: studentFormValues.student.housing2 || false,
        housing3: studentFormValues.student.housing3 || false,
        housing4: studentFormValues.student.housing4 || false,
        housing5: studentFormValues.student.housing5 || false,
        housing6: studentFormValues.student.housing6 || false,
        housing7: studentFormValues.student.housing7 || false,
        housing8: studentFormValues.student.housing8 || false,
        housing9: studentFormValues.student.housing9 || false,
        notes: studentFormValues.student.notes,
        birthCertificateFilePath: studentFormValues.student.file1.file,
        grades1FilePath: studentFormValues.student.file2.file,
        grades2FilePath: studentFormValues.student.file3.file,
        grades3FilePath: studentFormValues.student.file4.file,
        healthAppraisalFilePath: studentFormValues.student.file5.file,
        hasSibling: studentFormValues.student.hasSibling,
        siblingInfo: (studentFormValues.student.hasSibling === 'No'
          || studentFormValues.student.siblingInfo === "" )? null : studentFormValues.student.siblingInfo.trim(),
      })

    const input = {
      email: studentFormValues.email.toLowerCase(),
      firstName: studentFormValues.parentFirstName,
      lastName: studentFormValues.parentLastName,
      primaryContactPhone: studentFormValues.pPhone,
      secondaryPhone: studentFormValues.secondaryPhone,
      phoneType: studentFormValues.phoneType,
      emergencyFirstName: studentFormValues.emergencyFirstName,
      emergencyLastName: studentFormValues.emergencyLastName,
      emergencyRelationship: studentFormValues.emergencyRelationship,
      secondaryPhoneType: studentFormValues.secondaryPhoneType,
      address: studentFormValues.address,
      city: studentFormValues.city,
      state: studentFormValues.state,
      postalCode: studentFormValues.postalCode,
      relationship: studentFormValues.relation,
      freeAndReduced: studentFormValues.freeAndReduced,
      homeDistrictQ1: studentFormValues.homeDistrictQ1,
      homeDistrictQ2: studentFormValues.homeDistrictQ2,
      signature: studentFormValues.signature,
      //applications: [...studentFormValues]
      applications: [studentApplications]
    }
    register({
      variables: { input }
    })
    //console.log(applicationId);
  }

  const navigate = useNavigate();

  if (data) {
    if (data.register && data.register.formId) {
      navigate(`/confirmation/${data.register.formId}/${data.register.email}`);
    } else {
      navigate(`/error`);
    }
  }
  return (
    <StepsContainer done={registerApplication} studentFormValues={studentFormValues} setStudentFormValues={setStudentFormValues} />
  );
};

export default RegisterForm1;

